import React from 'react'

import H from 'history'
import _, { capitalize, compact } from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import { useHistory } from 'react-router-dom'

import { ActivityWrapper, ContributionCounterIcon } from 'components/CompanyHome/styles'
import { Compose, useComposer } from 'components/Composer'
import CrmIcon from 'components/CrmIcon'
import GlobalPersonLink from 'components/GlobalPersonLink'
import TimeAgo from 'components/TimeAgo'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import { DropdownMenuItem } from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisorship, useTeam, useTeamSlug } from 'store/hooks'
import { DataCard } from 'ui-components/DataCard'

import api from 'utils/api'
import { ConnectionIntroRequestBlueprint } from 'utils/api/connection_intro_requests'
import { listItemNames } from 'utils/constants/list'
import {
  Activity,
  AdvisorModel,
  AgreementBlueprint,
  CommentBlueprint,
  CompanyBlueprint,
  CompanyHistoryBlueprint,
  CompanyListAccessorBlueprint,
  CompanyListBlueprint,
  CompanyListItemBlueprint,
  GroupModel,
  MessageBlueprint,
  MessageBlueprintActivityView,
  Offer,
  StyleProps,
} from 'utils/types'
import { IntroRequest, InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'
import { VoteBlueprint } from 'utils/types/vote'
import { appendHttpsToDomain } from 'utils/url'

import ActivityCompanyAttributionModal from './ActivityCompanyAttributionModal'
import ActivityPersonAttributionModal from './ActivityPersonAttributionModal'
import LikeActivity, { TruncateText } from './LikeActivity'

interface Props extends StyleProps {
  activity: Activity
  profileView?: boolean
  refetchActivities?: () => void
  compact?: boolean
  advisorView?: boolean
  splitView?: boolean
  isCandidateProfilePage?: boolean
}

export const getData = ({
  profileView,
  advisorView,
  activity,
  history,
  compose,
  splitView,
  candidateView,
  isAdminOrEmployee,
  isCandidateProfilePage,
}: {
  profileView?: boolean
  advisorView?: boolean
  activity: Activity
  history?: H.History
  compose?: Compose
  splitView?: boolean
  candidateView?: boolean
  isAdminOrEmployee?: boolean
  isCandidateProfilePage?: boolean
}) => {
  const { activity_type, data, creator_user, user, team_name, team_slug, team_logo } = activity
  const isCreatedByCandidate = user?.id === creator_user?.id

  let object = activity.object

  let desc: React.ReactNode[] = []
  let title: React.ReactNode = ''
  let avatarUser: {
    uuid?: string
    name: string
    avatar_url?: string | null | React.ReactNode
    email?: string
    advisor_uuid?: string
    type?: 'advisor' | 'user' | 'email' | 'team' | 'GlobalPerson'
  } = user || { name: team_name, avatar_url: team_logo }
  let menuItems: (DropdownMenuItem | boolean | undefined)[] = []
  let cta: React.ReactNode = null

  if (advisorView) {
    avatarUser.name = 'You'
  }

  const companyUrl = (company: CompanyBlueprint) => {
    if (activity_type === 'request_intro_v2' && company?.company_list?.portfolio) {
      return `/${team_slug}/lists/${company?.company_list?.slug}/${
        company?.uuid || company?.requestable_uuid
      }`
    }
    return `/${team_slug}/items/${company?.uuid || company?.requestable_uuid}`
  }

  const truncatedRecipients = () => {
    object = object as MessageBlueprintActivityView
    return `${object.recipients.slice(0, 2).join(', ')}${
      object.recipients.length > 2 ? `, and ${object.recipients.length - 2} more` : ''
    }`
  }

  const viewMemberItem = (uuid: string, subtab?: string) =>
    !advisorView &&
    ({
      label: 'View Member',
      onSelect: () => history?.push(`/${team_slug}/members/${uuid}/${subtab || ''}`),
    } as DropdownMenuItem)

  const viewMessageItem = (uuid: string, draft: boolean) =>
    ({
      label: 'View Message',
      onSelect: () =>
        draft
          ? compose?.({ messageUuid: uuid, team_slug: team_slug })
          : history?.push(`/${team_slug}/messages/${uuid}`),
    } as DropdownMenuItem)

  const createConnectionIntroDraft = (connectionIntroRequest: ConnectionIntroRequestBlueprint) => {
    api
      .draftConnectionIntroRequest(connectionIntroRequest, team_slug)
      .then(({ data: { sent_message_uuid, draft_message_uuid } }) => {
        compose?.({ messageUuid: draft_message_uuid || sent_message_uuid, team_slug: team_slug })
      })
  }

  const viewMembersItem = {
    label: 'View Members',
    onSelect: () => history?.push(`/${team_slug}/members`),
  } as DropdownMenuItem

  const viewListItem = (companyList = object as CompanyListBlueprint) =>
    ({
      label: 'View List',
      onSelect: () => {
        const teamSlug = advisorView ? companyList.owning_team.slug : team_slug
        history?.push(`/${teamSlug}/lists/${companyList.slug}`)
      },
    } as DropdownMenuItem)

  const setAvatarUser = (name: string, avatar_url: string) => {
    const newAvatarUser = {
      name: name,
      avatar_url: avatar_url,
    }
    return [newAvatarUser, name]
  }

  const renderCompany = (company: CompanyBlueprint) => {
    return (
      <Typography
        component="link"
        color={'link'}
        className="hover:underline"
        to={companyUrl(company)}
      >
        {company?.name}
      </Typography>
    )
  }

  const renderName = (
    user?: typeof avatarUser,
    type = user?.type || 'user',
    splitView?: boolean,
  ) => {
    if (user === null || (user === undefined && !avatarUser)) {
      return <Tooltip label={'Now deleted'}>{capitalize(type)}</Tooltip>
    }
    user ||= avatarUser

    if (advisorView) {
      return 'You'
    } else if (splitView) {
      return user.name || user.email
    } else {
      if (!profileView && (user.advisor_uuid || type === 'advisor')) {
        return (
          <Typography
            component="link"
            color={'link'}
            className="hover:underline"
            to={`/${team_slug}/members/${user.advisor_uuid || user.uuid}`}
          >
            {user.name || user.email}
          </Typography>
        )
      } else {
        return user.name || user.email
      }
    }
  }

  const renderRequestablePerson = (requestable: any, clickable = true) => {
    if (clickable) {
      return (
        <Typography
          component="link"
          color={'link'}
          className="hover:underline"
          to={`/${team_slug}/person/${requestable.global_person_uuid}`}
        >
          {requestable.name}
        </Typography>
      )
    } else {
      return requestable.name
    }
  }

  const renderCandidate = (candidate: any, clickable = true, isOwnProfile = false) => {
    if (clickable) {
      return (
        <Typography
          component="link"
          color={'link'}
          className="hover:underline"
          to={`/${team_slug}/candidates/${candidate.uuid}`}
        >
          {isOwnProfile ? 'your profile' : candidate.user.name}
        </Typography>
      )
    } else {
      return candidate.user.name
    }
  }

  const renderJob = (job: any) => {
    return `${job.company_name} - ${job.headline}`
  }

  const renderIntroRequestSender = (introRequest: any, clickable = true) => {
    if (introRequest.requestable.item_type === 'Company') {
      return renderCompany(introRequest.requestable)
    } else if (
      introRequest.requestable.item_type === 'GlobalPerson' &&
      introRequest.candidate_profile.uuid === null
    ) {
      return renderRequestablePerson(introRequest.requestable, clickable)
    } else if (introRequest.requestable.item_type === 'InvestorCompanyJob') {
      return renderJob(introRequest.requestable)
    } else {
      return renderCandidate(introRequest.candidate_profile, clickable)
    }
  }

  const setAvatarAndNameForIntroRequest = (introRequest: any, isSentType: boolean = false) => {
    const obj = isSentType ? introRequest.user : introRequest.requestable
    if (!obj) return
    ;[avatarUser, name] = setAvatarUser(obj.name, obj.avatar_url)
    if (splitView && isCandidateProfilePage) {
      if ((isSentType && advisorView) || (!isSentType && candidateView)) {
        ;[avatarUser, name] = setAvatarUser('You', obj.avatar_url)
      }
    }
  }

  const setAvatarAndNameForCandidate = (candidate: any) => {
    ;[avatarUser, name] = isCreatedByCandidate
      ? setAvatarUser(candidate.user.name, candidate.global_person?.image_url)
      : setAvatarUser(creator_user?.name, creator_user?.avatar_url)
    if (splitView && candidateView) {
      ;[avatarUser, name] = isCreatedByCandidate
        ? setAvatarUser('You', candidate.global_person?.image_url)
        : setAvatarUser(creator_user?.name, creator_user?.avatar_url)
    }
  }

  const introRequestTitle = (messages: any, hasAdvisorMessage = true) => {
    let title
    if (splitView) {
      if (isCandidateProfilePage) {
        if (isAdminOrEmployee) {
          title = messages.splitView.candidateProfilePage.adminRole
        } else if (candidateView) {
          title = messages.splitView.candidateProfilePage.candidateView
        } else if (advisorView && hasAdvisorMessage) {
          title = messages.splitView.candidateProfilePage.advisorView
        }
      } else {
        title = messages.splitView.generalPage
      }
    } else {
      title = messages.generalView.full
    }

    return title
  }

  const candidateTitle = (messages: any) => {
    let title
    if (splitView) {
      if (isAdminOrEmployee || advisorView) {
        title = messages.splitView.adminRoleAndAdvisorView
      } else if (candidateView) {
        title = messages.splitView.candidateView
      }
    } else {
      title = messages.generalView.full
    }

    return title
  }

  const renderListName = (list: CompanyListBlueprint) => {
    return (
      <Typography
        component="link"
        color={'link'}
        className="hover:underline"
        to={`/${team_slug}/lists/${list.slug}`}
      >
        {list.name}
      </Typography>
    )
  }

  const renderOfferName = (offer: Offer) => {
    return (
      <Typography
        component="link"
        color={'link'}
        className="hover:underline"
        to={`/${team_slug}/resources/${offer.offer_type}/${offer.uuid}`}
      >
        {offer.title}
      </Typography>
    )
  }

  const renderCommentObjectName = (object: { uuid: string; name: string; url: string }) => {
    if (!object) {
      return <Typography>Unknown</Typography>
    } else {
      if (object.url && object.url.startsWith('http')) {
        return (
          <Typography component="link" color={'link'} className="hover:underline" to={object.url}>
            {object.name}
          </Typography>
        )
      } else {
        return <Typography component="span">{object.name}</Typography>
      }
    }
  }

  const renderMessageName = (message: MessageBlueprint) => {
    const subject = message.subject
      ? '“' + message.subject + '”'
      : message.draft
      ? 'a draft'
      : 'a message'

    if (message.draft) {
      return (
        <Typography
          component="button"
          color={'link'}
          className="hover:underline"
          onClick={() => compose?.({ messageUuid: message.uuid, team_slug: team_slug })}
        >
          {subject}
        </Typography>
      )
    }

    return (
      <Typography
        component="link"
        color={'link'}
        className="hover:underline"
        to={`/${team_slug}/messages/${message.uuid}`}
      >
        {subject}
      </Typography>
    )
  }

  let name = renderName(user, 'user', true)

  switch (activity_type) {
    case 'draft_sent':
      object = object as MessageBlueprintActivityView
      const subject = renderMessageName(object)
      const recipients = truncatedRecipients()

      if (data?.connection_intro_request) {
        const { requester, requestable } = data.connection_intro_request

        title = splitView ? (
          <>
            Sent intro request from {renderName(requester)} to{' '}
            {requestable.type === 'GlobalPerson' ? (
              <GlobalPersonLink person={requestable} />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {renderName()} sent intro request from {renderName(requester)} to{' '}
            {requestable.type === 'GlobalPerson' ? (
              <GlobalPersonLink person={requestable} />
            ) : (
              <></>
            )}
          </>
        )
      } else if (data?.request_type === 'deal_board_intro') {
        title = splitView ? (
          <>
            Sent an intro message {subject} to{' '}
            {data.global_person && (
              <GlobalPersonLink canAppendHeadline={false} person={data.global_person} />
            )}{' '}
            {recipients && <>({recipients})</>}
          </>
        ) : (
          <>
            {renderName()} sent an intro message {subject} to{' '}
            {data.global_person && (
              <GlobalPersonLink canAppendHeadline={false} person={data.global_person} />
            )}{' '}
            {recipients && <>({recipients})</>}
          </>
        )
      } else if (data?.request_type === 'connection_intro_queue') {
        title = splitView ? (
          <>
            Sent an intro request for{' '}
            {data.global_person && (
              <GlobalPersonLink canAppendHeadline={false} person={data.global_person} />
            )}
            {` `}
            {recipients && <>to {recipients}</>}
          </>
        ) : (
          <>
            {renderName()} sent an intro request for{' '}
            {data.global_person && (
              <GlobalPersonLink canAppendHeadline={false} person={data.global_person} />
            )}
            {` `}
            {recipients && <>to {recipients}</>}
          </>
        )
      } else {
        title = splitView ? (
          <>
            Sent {subject} to {recipients}
          </>
        ) : (
          <>
            {renderName()} sent {subject} to {recipients}
          </>
        )
      }
      menuItems = [viewMessageItem(object.uuid, object.draft)]
      break

    case 'view_deal_board':
      object = object as AdvisorModel
      title = splitView ? (
        <>Viewed the {team_name} Cabal page</>
      ) : (
        <>
          {renderName()} viewed the {team_name} Cabal page
        </>
      )
      menuItems = [viewMemberItem(object.uuid!)]
      break

    case 'joined_as_teammate':
      title = splitView ? <>Joined as a teammate</> : <>{renderName()} joined as a teammate</>
      break

    case 'advisor_import':
      object = object as AdvisorModel | undefined

      if (object) {
        title = splitView ? (
          <>Added member: {renderName(object, 'advisor')}</>
        ) : (
          <>
            {renderName()} added member: {renderName(object, 'advisor')}
          </>
        )
        menuItems = [viewMemberItem(object.uuid)]
      } else {
        title = splitView ? (
          <>Added {data?.new_advisors_count} members</>
        ) : (
          <>
            {renderName()} added {data?.new_advisors_count} members
          </>
        )
        menuItems = [viewMembersItem]
      }
      break

    case 'introduce_candidate':
      object = object as IntroRequest
      title = splitView ? (
        <>
          Introduced candidate {object?.user?.name} to {object?.investor_company_name}
        </>
      ) : (
        <>
          {renderName()} introduced candidate {object?.user?.name} to{' '}
          {object?.investor_company_name}
        </>
      )
      break

    case 'introduce_company_to_candidate':
      object = object as IntroRequest
      title = splitView ? (
        <>
          Introduced {object?.user?.name} to {object?.advisor_name}
        </>
      ) : (
        <>
          {renderName()} introduced {object?.user?.name} to {object?.advisor_name}
        </>
      )
      break

    case 'add_company':
      object = object as InvestorCompany
      title = splitView ? (
        <>Added a company: {object?.company_name}</>
      ) : (
        <>
          {renderName()} added a company: {object?.company_name}
        </>
      )
      break

    case 'update_company':
      object = object as InvestorCompany
      title = splitView ? (
        <>Updated a company: {object?.company_name}</>
      ) : (
        <>
          {renderName()} updated a company: {object?.company_name}
        </>
      )
      break

    case 'add_job':
      object = object as InvestorCompanyJob
      title = splitView ? (
        <>
          Added a job to {object?.investor_company?.company_name}: {object?.title}
        </>
      ) : (
        <>
          {renderName()} added a job to {object?.investor_company?.company_name}: {object?.title}
        </>
      )
      break

    case 'update_job':
      object = object as InvestorCompanyJob
      title = splitView ? (
        <>
          Updated a job from {object?.investor_company?.company_name}: {object?.title}
        </>
      ) : (
        <>
          {renderName()} updated a job from {object?.investor_company?.company_name}:{' '}
          {object?.title}
        </>
      )
      break

    case 'share_company':
      object = object as InvestorCompany
      avatarUser = creator_user!
      title = splitView ? (
        <>
          Offered to introduce {user?.name} to {object?.company_name}
        </>
      ) : (
        <>
          {renderName()} offered to introduce {user?.name} to {object?.company_name}
        </>
      )
      break

    case 'share_job':
      object = object as InvestorCompanyJob
      avatarUser = creator_user!
      title = splitView ? (
        <>
          Offered to introduce {renderName(user)} to {object?.investor_company?.company_name} (
          {object?.title})
        </>
      ) : (
        <>
          {renderName()} offered to introduce {renderName(user)} to{' '}
          {object?.investor_company?.company_name} ({object?.title})
        </>
      )
      break

    case 'share_candidate':
      object = object as AdvisorModel
      avatarUser = creator_user!
      title = splitView ? (
        <>
          Offered to introduce {renderName(user)} to {renderName(object, 'advisor')}
        </>
      ) : (
        <>
          {renderName()} offered to introduce {renderName(user)} to {renderName(object, 'advisor')}
        </>
      )
      break

    case 'share_portfolio':
      object = object as AdvisorModel
      title = splitView ? (
        <>Shared portfolio with {renderName(object, 'advisor')}</>
      ) : (
        <>
          {renderName()} shared portfolio with {renderName(object, 'advisor')}
        </>
      )
      break

    case 'request_intro':
      object = object as IntroRequest
      title = splitView ? (
        <>
          Requested intro to {object?.investor_company_name}
          {object?.job_title ? `: ${object?.job_title}` : ``}.{' '}
          {object?.talent_lead ? `Request notification delivered to ${object?.talent_lead}` : ``}
        </>
      ) : (
        <>
          {renderName()} requested intro to {object?.investor_company_name}
          {object?.job_title ? `: ${object?.job_title}` : ``}.{' '}
          {object?.talent_lead ? `Request notification delivered to ${object?.talent_lead}` : ``}
        </>
      )
      break

    case 'manual_intro_request_created':
      object = object as IntroRequest
      title = splitView ? (
        <>Created intro to {renderIntroRequestSender(object)}</>
      ) : (
        <>
          {renderName()} create intro to {renderIntroRequestSender(object)}
        </>
      )
      break

    case 'request_intro_v2':
      title = splitView ? (
        <>Requested intro to {renderIntroRequestSender(object)}</>
      ) : (
        <>
          {renderName()} requested intro to {renderIntroRequestSender(object)}
        </>
      )
      break

    case 'request_intro_accepted':
      setAvatarAndNameForIntroRequest(object)

      const requestIntroAcceptedMessages = {
        splitView: {
          candidateProfilePage: {
            adminRole: <>Accepted {renderName(object?.advisor, 'advisor')}'s intro request</>,
            candidateView: (
              <>Accepted {renderName(object?.advisor, 'advisor', true)}'s intro request</>
            ),
            advisorView: <>Accepted your intro request</>,
          },
          generalPage: advisorView ? (
            <>Accepted your intro request</>
          ) : (
            <>Accepted {renderName(object?.advisor, 'advisor')}'s intro request</>
          ),
        },
        generalView: {
          full: (
            <>
              {renderIntroRequestSender(object)} accepted intro request from{' '}
              {renderName(object?.advisor, 'advisor')}
            </>
          ),
        },
      }

      title = introRequestTitle(requestIntroAcceptedMessages)
      break

    case 'request_intro_declined':
      setAvatarAndNameForIntroRequest(object)

      const requestIntroDeclinedMessages = {
        splitView: {
          candidateProfilePage: {
            adminRole: <>Declined {renderName(object?.advisor, 'advisor')}'s intro request</>,
            candidateView: (
              <>Declined {renderName(object?.advisor, 'advisor', true)}'s intro request</>
            ),
            advisorView: <>Declined your intro request</>,
          },
          generalPage: advisorView ? (
            <>Declined your intro request</>
          ) : (
            <>Declined {renderName(object?.advisor, 'advisor')}'s intro request</>
          ),
        },
        generalView: {
          full: (
            <>
              {renderIntroRequestSender(object)} declined intro request from{' '}
              {renderName(object?.advisor, 'advisor')}
            </>
          ),
        },
      }

      title = introRequestTitle(requestIntroDeclinedMessages)
      break

    case 'request_intro_viewed':
      title = splitView ? (
        <>
          Viewed intro request from {renderName(object?.advisor, 'advisor')} to{' '}
          {renderIntroRequestSender(object)}
        </>
      ) : (
        <>
          {renderName()} viewed intro request from {renderName(object?.advisor, 'advisor')} to{' '}
          {renderIntroRequestSender(object)}
        </>
      )
      break

    case 'request_intro_sent_to_facilitator':
      const requestSentToFacilitator = {
        generalView: {
          full: (
            <>
              {renderName(object.requestor, 'requestor')} sent intro request to{' '}
              {object.requestable.name}
            </>
          ),
        },
      }

      title = introRequestTitle(requestSentToFacilitator)
      break

    case 'request_intro_sent_to_requestor':
      const requestSentToRequestor = {
        generalView: {
          full: (
            <>
              {renderName(object.initiator, 'initiator')} sent intro request to{' '}
              {object.requestor.name}
            </>
          ),
        },
      }

      title = introRequestTitle(requestSentToRequestor)
      break

    case 'request_intro_reminded':
      setAvatarAndNameForIntroRequest(object, false)
      const requestIntroRemindMessages = {
        splitView: {
          candidateProfilePage: {
            adminRole: <>Reminded an intro to {renderIntroRequestSender(object)}</>,
            candidateView: <>Reminded an intro to you</>,
            advisorView: <>Reminded an intro to {renderIntroRequestSender(object, false)}</>,
          },
          generalPage: <>Reminded intro request to {renderIntroRequestSender(object)}</>,
        },
        generalView: {
          full: (
            <>
              {renderName(object.advisor, 'advisor')} reminded intro request to{' '}
              {renderIntroRequestSender(object)}
            </>
          ),
        },
      }

      title = introRequestTitle(requestIntroRemindMessages)
      break

    case 'request_intro_sent':
      setAvatarAndNameForIntroRequest(object, true)

      const requestIntroSentMessages = {
        splitView: {
          candidateProfilePage: {
            adminRole: <>Requested an intro to {renderIntroRequestSender(object)}</>,
            candidateView: <>Requested an intro to you</>,
            advisorView: <>Requested an intro to {renderIntroRequestSender(object, false)}</>,
          },
          generalPage: <>Sent intro request to {renderIntroRequestSender(object)}</>,
        },
        generalView: {
          full: (
            <>
              {renderName(object.advisor, 'advisor')} sent intro request to{' '}
              {renderIntroRequestSender(object)}
            </>
          ),
        },
      }

      title = introRequestTitle(requestIntroSentMessages)
      break

    case 'request_intro_archived':
      title = splitView ? (
        <>
          Archived intro request from {renderName(object?.advisor, 'advisor')} to{' '}
          {renderIntroRequestSender(object)}
        </>
      ) : (
        <>
          {renderName()} archived intro request from {renderName(object?.advisor, 'advisor')} to{' '}
          {renderIntroRequestSender(object)}
        </>
      )
      break

    case 'request_intro_to_candidate':
      object = object as IntroRequest
      title = splitView ? (
        <>
          Requested intro to {object?.advisor_name}.{' '}
          {object?.talent_lead ? `Request notification delivered to ${object?.talent_lead}` : ``}
        </>
      ) : (
        <>
          {renderName()} requested intro to {object?.advisor_name}.{' '}
          {object?.talent_lead ? `Request notification delivered to ${object?.talent_lead}` : ``}
        </>
      )
      break

    case 'accept_intro':
      object = object as IntroRequest
      avatarUser = object?.accepted_or_declined_by_user || { name: 'Someone' }
      title = splitView ? (
        <>Accepted the intro requested by {object?.user?.name}</>
      ) : (
        <>
          {renderName()} at {object?.investor_company_name} accepted the intro requested by{' '}
          {object?.user?.name}
        </>
      )
      break

    case 'decline_intro':
      object = object as IntroRequest
      avatarUser = object.accepted_or_declined_by_user!
      title = splitView ? (
        <>Declined the intro requested by {renderName(object?.user)}</>
      ) : (
        <>
          {renderName()}
          at {object?.investor_company_name} declined the intro requested by{' '}
          {renderName(object?.user)}
        </>
      )
      break

    case 'view_portfolio':
      title = splitView ? (
        <>Viewed portfolio · {moment(activity.created_at).format('LT, MMM D YYYY')}</>
      ) : (
        <>
          {renderName()} viewed portfolio · {moment(activity.created_at).format('LT, MMM D YYYY')}
        </>
      )
      break

    case 'reroute_intro':
      object = object as IntroRequest
      avatarUser = { name: object.investor_company_name, avatar_url: object.investor_company_logo }
      title = splitView ? (
        <>Rerouted the intro requested by {renderName(object?.user)}</>
      ) : (
        <>
          {renderName()} rerouted the intro requested by {renderName(object?.user)}
        </>
      )
      break

    case 'connection_liked':
      object = object as VoteBlueprint
      title = splitView ? (
        <>
          Offered help with:{' '}
          {_.compact([object.global_person?.name, object.global_person?.headline]).join(' - ')}
        </>
      ) : (
        <>
          {renderName()} offered help with:{' '}
          {_.compact([object.global_person?.name, object.global_person?.headline]).join(' - ')}
        </>
      )
      break

    case 'company_liked':
      object = object as VoteBlueprint
      title = splitView ? (
        <>Offered help with: {object.company?.name}</>
      ) : (
        <>
          {renderName()} offered help with: {object.company?.name}
        </>
      )
      break

    case 'list_shared_by_team':
      object = object as CompanyListBlueprint
      title = splitView ? (
        <>
          Shared a list with {team_name}: {renderListName(object)}
        </>
      ) : (
        <>
          {renderName()} shared a list with {team_name}: {renderListName(object)}
        </>
      )
      menuItems = [viewListItem()]
      break

    case 'group_created':
      object = object as GroupModel
      title = splitView ? (
        <>Created a group: {object?.name}</>
      ) : (
        <>
          {renderName() || team_name} created a group: {object?.name}
        </>
      )
      const group_uuid = object?.uuid

      menuItems = [
        {
          label: 'View Group',
          onSelect: () => {
            history?.push(`/${team_slug}/members?filters=group:${group_uuid}`)
          },
        },
      ]
      break

    case 'list_created':
      {
        object = object as CompanyListBlueprint
        title = splitView ? (
          <>Created a new list: {renderListName(object)}</>
        ) : (
          <>
            {renderName()} created a new list: {renderListName(object)}
          </>
        )

        const list_slug = object?.slug

        menuItems = [
          {
            label: 'View List',
            onSelect: () => {
              history?.push(`/${team_slug}/lists/${list_slug}`)
            },
          },
        ]
      }
      break
    case 'list_accessor_added':
      {
        object = object as CompanyListAccessorBlueprint
        title = splitView ? (
          <>
            Shared a List: {renderListName(object.company_list!)} with {renderName(object.accessor)}
          </>
        ) : (
          <>
            {renderName() || team_name} shared a List: {renderListName(object.company_list!)} with{' '}
            {renderName(object.accessor)}
          </>
        )

        const list_slug = object?.company_list?.slug

        menuItems = [
          {
            label: 'View List',
            onSelect: () => {
              history?.push(`/${team_slug}/lists/${list_slug}`)
            },
          },
        ]
      }
      break

    case 'marketing':
    case 'recruiting':
    case 'advice':
    case 'other':
    case 'sales':
      let intro: string

      if (activity_type === 'sales') {
        intro = 'made an intro'
      } else if (activity_type === 'marketing') {
        intro = 'gave a boost'
      } else {
        intro = 'made a contribution'
      }

      title = splitView ? (
        <>
          {intro}: {activity.data?.description}
        </>
      ) : (
        <>
          {profileView ? 'You' : renderName()} {intro}: {activity.data?.description}
        </>
      )

      menuItems = compact([
        activity.user?.advisor_uuid && viewMemberItem(activity.user?.advisor_uuid),
      ])

      desc = compact([
        activity.target_team && activity.target_team?.slug !== team_slug && (
          <Typography
            component="link"
            to={`/${activity.target_team.slug}`}
            className="flex items-center gap-1 hover:underline"
          >
            <Avatar
              size="12px"
              src={activity.target_team?.logo}
              name={activity.target_team?.name}
            />{' '}
            {activity.target_team?.name}
          </Typography>
        ),
      ])
      break

    case 'advisor_offer':
      title = splitView ? (
        <>Made an offer: {activity.data?.description} </>
      ) : (
        <>
          {renderName()} made an offer: {activity.data?.description}{' '}
        </>
      )
      break

    case 'message_comment':
      object = object as CommentBlueprint
      title = splitView ? (
        <>Left a comment on {renderMessageName(object.attachable!)}</>
      ) : (
        <>
          {renderName()} left a comment on {renderMessageName(object.attachable!)}
        </>
      )
      menuItems = [viewMessageItem(object.attachable.uuid, object.attachable.draft)]
      break

    case 'comment':
      object = object as CommentBlueprint
      title = splitView ? (
        <>Left a comment on {renderCommentObjectName(object.attachable!)}</>
      ) : (
        <>
          {renderName()} left a comment on {renderCommentObjectName(object.attachable!)}
        </>
      )
      menuItems = object.attachable
        ? [viewMessageItem(object.attachable.uuid, object.attachable.draft)]
        : []
      break

    case 'share_draft':
      object = object as MessageBlueprintActivityView
      avatarUser = creator_user!
      title = splitView ? (
        <>
          Shared {renderMessageName(object)} with {renderName(user)}
        </>
      ) : (
        <>
          {renderName()} shared {renderMessageName(object)} with {renderName(user)}
        </>
      )
      menuItems = [viewMessageItem(object.uuid, object.draft)]
      break

    case 'connections_shared':
      const source = activity.data?.shared_via
      const count = activity.data?.connections_count
      const sourceName = source === 'google_contacts' ? 'Google Contacts' : 'LinkedIn'
      title = splitView ? (
        <>
          Shared {advisorView ? 'your' : 'their'} connections {source ? `from ${sourceName}` : ''}
          {count ? ` (${count.toLocaleString()} connections)` : ''}
        </>
      ) : (
        <>
          {renderName()} shared {advisorView ? 'your' : 'their'} connections{' '}
          {source ? `from ${sourceName}` : ''}
          {count ? ` (${count.toLocaleString()} connections)` : ''}
        </>
      )
      break

    case 'agreement_sent':
      object = object as AgreementBlueprint
      title = splitView ? (
        <>
          Sent {object.name} Agreement to {renderName(object.advisor, 'advisor')}
        </>
      ) : (
        <>
          {renderName()} sent {object.name} Agreement to {renderName(object.advisor, 'advisor')}
        </>
      )
      menuItems = [viewMemberItem(object.advisor?.uuid, 'agreements')]
      break

    case 'agreement_signed':
      object = object as AgreementBlueprint
      avatarUser = { ...object.advisor, type: 'advisor' }
      title = splitView ? (
        <>Signed {object.name} Agreement</>
      ) : (
        <>
          {renderName()} signed {object.name} Agreement
        </>
      )
      menuItems = [viewMemberItem(object.advisor?.uuid, 'agreements')]
      break

    case 'add_resource':
      object = object as Offer
      title = splitView ? (
        <>Added resource: {renderOfferName(object)}</>
      ) : (
        <>
          {renderName()} added resource: {renderOfferName(object)}
        </>
      )
      break

    case 'viewed_resource':
      object = object as Offer
      title = splitView ? (
        <>Viewed resource: {renderOfferName(object)}</>
      ) : (
        <>
          {renderName()} viewed resource: {renderOfferName(object)}
        </>
      )
      break

    case 'list_item_added':
      object = object as CompanyListItemBlueprint
      const listItemUuids = data!.list_item_uuids!
      const otherItemCount = listItemUuids.length - 1
      avatarUser.name = object.company?.name || object.name
      if (['salesforce', 'hubspot'].includes(data!.crm_provider!)) {
        avatarUser.avatar_url = (
          <CrmIcon
            style={{ width: '32px' }}
            className="text-xl text-center"
            crm={data!.crm_provider!}
          />
        )
      } else {
        avatarUser.avatar_url = object.company?.logo_url
      }

      name = capitalize(data!.crm_provider!)

      title = (
        <>
          {renderCompany(object.company)}
          {otherItemCount > 0 ? ` and ${otherItemCount} other` : ''}{' '}
          {otherItemCount > 0 &&
            pluralize(
              listItemNames[object.company_list!.list_type || 'companies'],
              otherItemCount,
            )}{' '}
          {pluralize('was', listItemUuids.length)} added to {renderListName(object.company_list!)}
        </>
      )

      menuItems = [viewListItem(object.company_list)]
      break

    case 'company_stage_change':
      object = object as CompanyHistoryBlueprint
      avatarUser.name = object.company.name
      if (['salesforce', 'hubspot'].includes(data!.crm_provider!)) {
        avatarUser.avatar_url = (
          <CrmIcon
            style={{ width: '32px' }}
            className="text-xl text-center"
            crm={data!.crm_provider!}
          />
        )
      } else {
        avatarUser.avatar_url = object.company.logo_url
      }

      name = capitalize(data!.crm_provider!)
      title = (
        <>
          {renderCompany(object.company)} changed stage: {object.stage_name}
        </>
      )
      cta = (
        <Typography fontSize="12" color="link" component="link" to={companyUrl(object.company)}>
          View connections
        </Typography>
      )
      break

    case 'connection_intro_request_created':
      object = object as ConnectionIntroRequestBlueprint

      title = splitView ? (
        <>
          Requested an intro to <GlobalPersonLink person={object.requestable} />
        </>
      ) : (
        <>
          {renderName()} requested an intro to <GlobalPersonLink person={object.requestable} />
        </>
      )

      if (!object.completed_at) {
        cta = (
          <Typography
            fontSize="12"
            color="link"
            component="button"
            onClick={() => createConnectionIntroDraft(object as ConnectionIntroRequestBlueprint)}
          >
            Draft Intro
          </Typography>
        )
      } else {
        cta = (
          <Typography
            fontSize="12"
            color="link"
            component="link"
            to={`/${team_slug}/messages/${object.sent_message_uuid}`}
          >
            View Message
          </Typography>
        )
      }

      break

    case 'list_view':
      {
        object = object as CompanyListBlueprint
        title = splitView ? (
          <>Viewed list: {renderListName(object)}</>
        ) : (
          <>
            {renderName()} viewed list: {renderListName(object)}
          </>
        )

        const list_slug = object?.slug

        menuItems = [
          {
            label: 'View List',
            onSelect: () => {
              history?.push(`/${team_slug}/lists/${list_slug}`)
            },
          },
        ]
      }
      break

    case 'candidate_view':
      if (splitView && isCandidateProfilePage && candidateView) {
        ;[avatarUser, name] = setAvatarUser('Someone', undefined)
      }

      const candidateViewMessages = {
        splitView: {
          candidateProfilePage: {
            adminRole: <>Viewed this profile</>,
            candidateView: <>Viewed your profile</>,
          },
          generalPage: <>Viewed profile: {renderCandidate(object)}</>,
        },
        generalView: {
          full: (
            <>
              {renderName()} viewed profile: {renderCandidate(object)}
            </>
          ),
        },
      }

      title = introRequestTitle(candidateViewMessages, false)
      break

    case 'candidate_created':
      setAvatarAndNameForCandidate(object)

      const candidateCreatedMessages = {
        splitView: {
          adminRoleAndAdvisorView: isCreatedByCandidate ? (
            <>Created their profile</>
          ) : (
            <>Created {renderCandidate(object)} profile</>
          ),
          candidateView: <>Created {renderCandidate(object, true, true)}</>,
        },
        generalView: {
          full: isCreatedByCandidate ? (
            <>
              {renderCandidate(object)} created their profile (invited by {object?.owner?.name})
            </>
          ) : (
            <>
              {renderName(creator_user)} created {renderCandidate(object)} profile
            </>
          ),
        },
      }

      title = candidateTitle(candidateCreatedMessages)
      break

    case 'candidate_updated':
      setAvatarAndNameForCandidate(object)

      const candidateUpdatedMessages = {
        splitView: {
          adminRoleAndAdvisorView: isCreatedByCandidate ? (
            <>Updated their profile</>
          ) : (
            <>Updated {renderCandidate(object)} profile</>
          ),
          candidateView: <>Updated your profile</>,
        },
        generalView: {
          full: isCreatedByCandidate ? (
            <>{renderCandidate(object)} updated their profile</>
          ) : (
            <>
              {renderName(creator_user)} updated {renderCandidate(object)} profile
            </>
          ),
        },
      }

      title = candidateTitle(candidateUpdatedMessages)
      break

    case 'candidate_uploaded_documents':
      setAvatarAndNameForCandidate(object)

      const candidateUploadedDocumentsMessages = {
        splitView: {
          adminRoleAndAdvisorView: isCreatedByCandidate ? (
            <>Added files to their profile</>
          ) : (
            <>Added files to {renderCandidate(object)} profile</>
          ),
          candidateView: <>Added files to your profile</>,
        },
        generalView: {
          full: isCreatedByCandidate ? (
            <>{renderCandidate(object)} added files to their profile</>
          ) : (
            <>
              {renderName(creator_user)} added files to {renderCandidate(object)} profile
            </>
          ),
        },
      }

      title = candidateTitle(candidateUploadedDocumentsMessages)
      break

    default:
      title = 'Unknown'
  }

  menuItems = _.compact(menuItems)

  return { name, title, avatarUser, menuItems: menuItems as DropdownMenuItem[], desc, cta }
}

const ActivityRow: React.FC<Props> = ({
  activity,
  profileView = false,
  refetchActivities,
  compact = false,
  splitView = false,
  advisorView,
  isCandidateProfilePage = false,
  ...restProps
}) => {
  const { activity_type, created_at } = activity
  const { showModal } = useModal()
  const { compose } = useComposer()
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const { advisor } = useAdvisorship(teamSlug)
  advisorView ||= !!advisor && !advisor.role
  const candidateView = !!advisor && advisor.role === 'candidate'
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  if (['connection_liked', 'company_liked'].includes(activity_type) && team)
    return (
      <LikeActivity
        team={team}
        refetchActivities={refetchActivities}
        activity={activity}
        profileView={profileView}
        compact={compact}
        advisorView={advisorView}
        splitView={splitView}
        {...restProps}
      />
    )

  const { name, title, avatarUser, menuItems, desc, cta } = getData({
    profileView,
    advisorView,
    activity,
    history,
    compose,
    splitView,
    candidateView,
    isAdminOrEmployee,
    isCandidateProfilePage,
  })

  menuItems.push({
    label: 'Attach Company',
    onSelect: () =>
      showModal(
        (r) => (
          <ActivityCompanyAttributionModal
            resolve={r}
            teamSlug={activity.team_slug}
            activity={activity}
            onSave={() => refetchActivities?.()}
          />
        ),
        'activity-attribution-modal',
      ),
  })

  menuItems.push({
    label: 'Attach Person',
    onSelect: () =>
      showModal(
        (r) => (
          <ActivityPersonAttributionModal
            resolve={r}
            teamSlug={activity.team_slug}
            activity={activity}
            onSave={() => refetchActivities?.()}
          />
        ),
        'activity-attribution-modal',
      ),
  })

  if (!!activity.global_companies?.length) {
    desc.push(
      <div className="flex items-center gap-1">
        {activity.global_companies.map((company) => (
          <a
            href={appendHttpsToDomain(company.domain)}
            target="_blank"
            className="flex items-center gap-1 hover:underline"
            rel="noreferrer"
            key={company.uuid}
          >
            <Avatar size="12px" src={company.logo_url} name={company.name} />
            <span>{company.name}</span>
          </a>
        ))}
      </div>,
    )
  }

  if (!!activity.global_people?.length) {
    desc.push(
      <div className="flex items-center gap-1">
        {activity.global_people.map((person) => (
          <span className="flex items-center gap-1" key={person.uuid}>
            <span>{person.name}</span>
          </span>
        ))}
      </div>,
    )
  }

  if (created_at) {
    desc.push(<TimeAgo datetime={created_at} />)
  }

  if (profileView || compact) {
    desc.push(`${activity.team_name}`)
  }

  return (
    <>
      {splitView && (
        <DataCard
          className="h-auto min-h-[50px] py-2"
          title={
            <>
              <Typography fontSize="12">
                <Typography fontWeight={600}>{name}</Typography>{' '}
                <Typography color="fog_rain">
                  <TimeAgo datetime={created_at} short />
                </Typography>
              </Typography>
            </>
          }
          avatarUrl={avatarUser.avatar_url || undefined}
          avatarName={avatarUser.name}
          placeholderIcon={name == 'Someone' && <i className="far fa-user"></i>}
          avatarAlign={'top'}
          description={
            <TruncateText
              key={`comment-body`}
              color="primary"
              component="div"
              className="ql-output"
            >
              {title}
            </TruncateText>
          }
          menuItems={menuItems.length > 0 ? menuItems : undefined}
        />
      )}
      {compact && (
        <ActivityWrapper>
          <div className="flex">
            <Typography fontSize="12" color="fog_rain" className="mr-2">
              <ContributionCounterIcon className="fa fa-trophy mr-1" />
              {'1'}
            </Typography>

            <Typography fontSize="12">{title}</Typography>
          </div>
        </ActivityWrapper>
      )}
      {!splitView && !compact && (
        <DataCard
          {...restProps}
          avatarUrl={avatarUser.avatar_url || undefined}
          avatarName={avatarUser.name}
          activityTitle={title}
          description={desc}
          cta={cta}
          menuItems={menuItems.length > 0 ? menuItems : undefined}
        />
      )}
    </>
  )
}

export default ActivityRow
