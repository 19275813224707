import React from 'react'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import { cabalToast } from 'ui-components/Toast'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'

interface Props {
  selectedItems: ISalesCompany[] | ISalesPeople[]
}

const SalesListBulkActions: React.FC<Props> = ({ selectedItems }) => {
  const { adminView, refetchCompanyList, companyListData } = useSalesListContext()

  const { mutate: deleteItem } = useMutation(
    () =>
      api.removeElementFromSalesList(
        companyListData.uuid,
        selectedItems.map((item) => item.uuid),
      ),
    {
      onSuccess: () => {
        refetchCompanyList()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const { mutate: exportCompanyData } = useMutation(
    () =>
      callApi(
        api.exportSalesList,
        selectedItems.map((item) => item.uuid),
        companyListData.uuid,
      ),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Company data export initiated. Check your email for the file.',
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to export company data.' })
      },
    },
  )

  const handleOnSelect = (action: 'delete' | 'export') => {
    if (selectedItems.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        deleteItem()
      }
    }

    if (action === 'export') {
      exportCompanyData()
    }
  }

  const bulkMenuItems = [
    {
      label: 'Delete',
      onSelect: () => handleOnSelect('delete'),
    },
    {
      label: 'Export',
      onSelect: () => handleOnSelect('export'),
    },
  ]

  return (
    <>
      {adminView && (
        <BulkActions disabled={selectedItems.length === 0} bulkMenuItems={bulkMenuItems} />
      )}
    </>
  )
}

export default SalesListBulkActions
