import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { StatusWrapper } from 'containers/ListView/Companies/styles'
import CabalButton from 'global/CabalButton'
import { MultiSelect, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { ExternalSource } from 'utils/types'

interface Props {
  resolve: () => void
  teamSlug: string
}

type affinityList = {
  id: number
  name: string
}

const AffinitySettingModal: React.VFC<Props> = ({ resolve, teamSlug }) => {
  const defaultValues = {
    provider: 'affinity',
  }

  const [data, setData] = useState<ExternalSource | null>({ ...defaultValues })
  const [selectedLists, setSelectedLists] = useState<affinityList[]>([])
  const [affinityListUnderSync, setAffinityListUnderSync] = useState(false)
  const [affinityListOptions, setAffinityListOptions] = useState<{
    lists: { id: string; name: string }[]
  }>([])
  const [requestCountAffinityStatus, setRequestCountAffinityStatus] = useState(0)
  const maxRequestsAffinityStatus = 15
  const [apiKeyEditButtonVisible, setApiKeyEditButtonVisible] = useState(true)

  const { mutate: handleSync, isLoading: _isLoadingLists } = useMutation(
    () => callApi(api.syncAffinityLists),
    {
      onSuccess: ({ success, status }) => {
        setRequestCountAffinityStatus(0)
        setAffinityListUnderSync(true)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong. Please contact support.' })
        setApiKeyEditButtonVisible(true)
        setAffinityListUnderSync(false)
      },
    },
  )
  const { mutate: handleAdd, isLoading } = useMutation(
    () => callApi(api.addExternalSource, data, teamSlug),
    {
      onSuccess: ({ source }) => {
        setData(source)
        setRequestCountAffinityStatus(0)
        setAffinityListUnderSync(true)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong. Please contact support.' })
        setRequestCountAffinityStatus(0)
        setApiKeyEditButtonVisible(true)
        setAffinityListUnderSync(false)
      },
    },
  )

  const { mutate: handleDelete } = useMutation(
    () => callApi(api.deleteExternalSource, data?.uuid, teamSlug),
    {
      onSuccess: () => {
        setData({ ...defaultValues })
        cabalToast({ style: 'success', content: 'The external source was successfully deleted!' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: updateAffinityListFiltersMutation, isLoading: _isUpdating } = useMutation(
    ['updateAffinityList', teamSlug],
    () =>
      callApi(api.updateAffinityListFilters, {
        affinity_lists: { selected_lists: selectedLists },
      }),
    {
      onSuccess: ({ app }) => {
        cabalToast({
          id: 'open-lists-from-affinity-integration',
          style: 'success',
          content: (
            <>
              Lists have been successfully added.
              <Typography fontSize="14" fontWeight={600} className="underline">
                <Link
                  to={`/${teamSlug}/lists`}
                  onClick={() => {
                    resolve()
                    toast.remove('open-lists-from-affinity-integration')
                  }}
                >
                  View Lists
                </Link>
              </Typography>
            </>
          ),
        })
        resolve()
      },
      onError: (error) => {
        cabalToast({ style: 'error', content: 'Something went wrong. Please contact support.' })
      },
    },
  )

  const loadAffinityLists = () => {
    callApi(api.getAffinityLists).then((response) => {
      setAffinityListOptions(response)
    })
  }

  const loadData = () => {
    callApi(api.getSelectedAffinityLists).then((response) => {
      setSelectedLists(response.lists)
    })
  }

  useEffect(() => {
    loadData()
    loadAffinityLists()
  }, [])

  useQuery(
    [teamSlug, 'getExternalSource'],
    () => callApi(api.getAffinityExternalSource, teamSlug),
    {
      onSuccess: ({ source }) => {
        setData(source || { ...defaultValues })
      },
    },
  )

  useQuery(['affinity_sync_status'], () => callApi(api.getAffinitySyncStatus), {
    enabled: affinityListUnderSync,
    refetchInterval: 2000,
    onSuccess: (affinityStatus) => {
      if (affinityStatus?.success && affinityStatus?.status?.synced_at) {
        callApi(api.getAffinityLists).then((response) => {
          setAffinityListOptions(response)
          setApiKeyEditButtonVisible(true)
          setAffinityListUnderSync(false)
        })
      } else {
        if (requestCountAffinityStatus >= maxRequestsAffinityStatus) {
          cabalToast({ style: 'error', content: 'Something went wrong. Please contact support.' })
          setApiKeyEditButtonVisible(true)
          setAffinityListUnderSync(false)
        } else {
          setRequestCountAffinityStatus(requestCountAffinityStatus + 1)
        }
      }
    },
  })

  const renderRightButton = () => {
    if (data?.uuid) {
      return (
        <CabalButton
          onClick={() => updateAffinityListFiltersMutation()}
          working={affinityListUnderSync}
          disabled={affinityListUnderSync}
        >
          Done
        </CabalButton>
      )
    } else {
      return (
        <CabalButton onClick={() => handleAdd()} working={isLoading} disabled={isLoading}>
          Save
        </CabalButton>
      )
    }
  }

  return (
    <Modal
      show
      onHide={resolve}
      header={'Setup Affinity integration'}
      rightActions={renderRightButton()}
    >
      <div className={'mt-3'}></div>
      {affinityListUnderSync ? (
        <StatusWrapper className="w-1/2">
          <div className="text-center">
            <Typography fontWeight={700} fontSize={'18'} color={'done'} className="inline-block">
              <i className="far fa-sync animate-spin-slow" />
            </Typography>
            <Typography fontSize="14" lineHeight={1} className="ml-4">
              Loading your lists <br />
            </Typography>
          </div>
        </StatusWrapper>
      ) : (
        <>
          <div id={'affinity-api-key'} className={'mb-3 flex'}>
            <TextInput
              value={data?.uuid ? '******' : data?.api_key}
              onChange={(e) => {
                !data?.uuid && setData({ ...data, api_key: e.target.value })
              }}
              label={!data?.uuid ? 'Add Affinity API Key' : 'Affinity API Key'}
              placeholder={'Affinity API Key'}
              className="flex-grow mr-2"
              valueType={'non-empty'}
              disabled={!!data?.uuid && apiKeyEditButtonVisible}
              labeled
              id={'affinity-api-key-txt-input'}
            />
            {!!data?.uuid && apiKeyEditButtonVisible && (
              <div className="self-end">
                <CabalButton
                  className="grow-0"
                  leftIcon={<i className="fa fa-pencil pr-1" />}
                  variant="secondary"
                  onClick={() => {
                    setData({ ...data, api_key: '', uuid: '' })
                    setApiKeyEditButtonVisible(false)
                  }}
                >
                  Edit
                </CabalButton>
              </div>
            )}
          </div>
          {data?.uuid && (
            <div className={'mb-2'}>
              <Typography color="fog_rain">
                <ModalInputLabel className="pb-2">Select lists to add to Cabal</ModalInputLabel>
              </Typography>
              <div id={'affinity-api-key'} className={'mb-3 flex'}>
                <MultiSelect
                  value={selectedLists}
                  onChange={(selectedOptions: affinityList[]) => {
                    setSelectedLists(selectedOptions)
                  }}
                  className="flex-grow mr-2 pb-2"
                  placeholder={
                    affinityListOptions?.lists?.length > 0 ? 'Available Lists' : 'No Lists found'
                  }
                  isClearable
                  compactPlaceholder
                  options={
                    affinityListOptions?.lists?.map((list) => ({
                      label: list.name,
                      value: list.id,
                    })) || []
                  }
                />
                {!(affinityListOptions?.lists?.length > 0) && (
                  <div className="self-end pb-2.5">
                    <CabalButton
                      className="grow-0"
                      variant="secondary"
                      onClick={() => handleSync()}
                    >
                      Refresh
                    </CabalButton>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  )
}

export default AffinitySettingModal
