import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import moment from 'moment'
import { useMutation } from 'react-query'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import CandidatePersonalInfo from 'components/MemberOnboardingPage/CandidatePersonalInfo'
import TalentProfile from 'components/MemberOnboardingPage/TalentProfile'
import AddConnectionsPage from 'containers/AddConnectionsPage'
import {
  CandidateProfileContext,
  CandidateProfileModalInput,
  initialCandidateProfile,
} from 'containers/CandidateProfileModal'
import ProfileSetup from 'containers/OnboardingV2/ProfileSetup'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useAdvisorship, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import {
  DEFAULT_CANDIDATE_ONBOARDING_MESSAGE,
  DEFAULT_ONBOARDING_MESSAGE,
} from 'utils/constants/onboarding'

import BgImage from '../../../../public/desktopnew-bg.png'
import NoiseDark from '../../../../public/noise-dark.png'
import Logo from '../../images/logo.svg'

const Background = styled.div`
  background-color: #0e151f;
  background-image: url(${BgImage}), url(${NoiseDark});
  background-repeat: no-repeat, repeat;
  background-size: 100% auto, auto;
`

export const Card = styled.div`
  ${tw`mt-12 p-2 sm:p-4 mx-1 rounded-xl sm:w-[520px] sm:mx-auto`}
  background: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
`

const BorderBox = styled.div`
  border: solid 1px ${(props) => props.theme.colors.rain};
  border-radius: 4px;
`

function WelcomeMessage(props: { onContinue: () => void }) {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const prompt_user =
    team?.admins_and_members &&
    team?.admins_and_members.find((u) => u.uuid === team.sales_ask_prompt_user_id)

  const candidateInviteLink = useSearchParam('candidates_invite_link')
  const candidateOwnerId = useSearchParam('owner_uuid')
  const candidateOnboarding = candidateInviteLink || candidateOwnerId
  const [candidateOwner, setCandidateOwner] = React.useState<any>(null)
  const msg = candidateOnboarding
    ? team?.talent_onboarding_message || DEFAULT_CANDIDATE_ONBOARDING_MESSAGE
    : team?.sales_asks_prompt || DEFAULT_ONBOARDING_MESSAGE

  React.useEffect(() => {
    if (candidateOnboarding) {
      api.getProfile(candidateOwnerId).then(({ data }) => setCandidateOwner(data))
    }
  }, [])

  return (
    <Card>
      <div className="flex flex-col items-center gap-6 px-2">
        <Avatar src={team?.logo} name={team?.name} size="64" />

        <div className="flex gap-2 self-stretch">
          {!candidateOnboarding && (
            <Avatar src={prompt_user?.avatar_url} name={prompt_user?.name} size="40" />
          )}
          {candidateOnboarding && (
            <Avatar src={candidateOwner?.avatar_url} name={candidateOwner?.name} size="40" />
          )}

          <div className="flex-1">
            {!candidateOnboarding && (
              <Typography fontWeight={600}>{prompt_user?.first_name}</Typography>
            )}
            {candidateOnboarding && (
              <Typography fontWeight={600}>{candidateOwner?.first_name}</Typography>
            )}

            <BorderBox className="mt-2 p-3">
              <Typography component="div" className="whitespace-pre-wrap" color="fog" fontSize="14">
                {msg}
              </Typography>
            </BorderBox>
          </div>
        </div>

        <CabalButton
          variant="primary"
          rightIcon={<i className="far fa-arrow-right" />}
          onClick={props.onContinue}
        >
          Continue
        </CabalButton>
      </div>
    </Card>
  )
}

function getStartOfMonthISO(month: number, year: number): string {
  return moment
    .utc()
    .month(month - 1)
    .year(year)
    .startOf('month')
    .toISOString()
}

function getEndOfMonthISO(month: number, year: number): string {
  return moment
    .utc()
    .month(month - 1)
    .year(year)
    .endOf('month')
    .toISOString()
}

interface MemberOnboardingSteps {
  welcome: boolean
  name: boolean
  connections: boolean
  personal_info: boolean
  talent_profile: boolean
}

export default function MemberOnboardingPage() {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const [currentStep, setCurrentStep] = React.useState(0)
  const { team } = useTeam(teamSlug)
  const { reloadAdvisorship } = useAdvisorship(teamSlug)
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'linear',
  })
  const [candidateProfile, setCandidateProfile] =
    React.useState<CandidateProfileModalInput>(initialCandidateProfile)
  const [working, setWorking] = React.useState(false)
  const stepsParam = useSearchParam('steps')

  // if a candidate gets here without using the link
  // we assume the user who added them is the owner
  const ownerUuidParam = useSearchParam('owner_uuid') || team?.advisor?.added_by_uuid

  const listUuidParam = useSearchParam('list_uuid')
  const allCandidatesListUuidsParam = useSearchParam('all_candidates_list_uuids')

  const force_candidate_onboarding =
    team?.advisor?.role === 'candidate' && !user.has_candidate_profile
  // we've identified a candidate that needs to go through onboarding
  // but they got here without following a talent invite link
  // we don't know who owns the candidate, so they need to follow the link
  const candidate_needs_to_use_link = force_candidate_onboarding && !ownerUuidParam

  const steps = React.useMemo(() => {
    let steps: MemberOnboardingSteps
    if (stepsParam) {
      steps = { ...JSON.parse(window.atob(stepsParam)), name: true }
    } else {
      steps = {
        welcome: true,
        name: true,
        connections: force_candidate_onboarding ? false : true,
        personal_info: force_candidate_onboarding ? true : false,
        talent_profile: force_candidate_onboarding ? true : false,
      }
    }
    steps = { ...steps }

    const stepsList: (keyof MemberOnboardingSteps)[] = [
      'welcome',
      'name',
      'personal_info',
      'talent_profile',
      'connections',
    ]

    return stepsList.filter((step) => steps[step])
  }, [stepsParam])

  const currentStepName = steps[currentStep]

  let allCandidatesListUuids: string[] = []
  if (allCandidatesListUuidsParam) {
    allCandidatesListUuids = JSON.parse(window.atob(allCandidatesListUuidsParam))
  }
  let companyListUuids: string[] = []
  if (listUuidParam == null || listUuidParam == 'all-candidates') {
    companyListUuids = allCandidatesListUuids ?? []
  } else {
    companyListUuids = [listUuidParam]
  }

  const finalizeWithSuccessAndShowToast = (message: string, candidateProfileUuid: string) => {
    cabalToast({
      style: 'success',
      content: message,
    })
    saveAdvisorMutation.mutateAsync()
    window.location.href = `/${teamSlug}`
    setWorking(false)
  }

  const showError = (message: string, error: any) => {
    setWorking(false)
    error.response
      ? cabalToast({
          style: 'error',
          content: `${message}: ${error.response.data.errors.message}`,
        })
      : cabalToast({
          style: 'error',
          content: message,
        })
  }

  const createCandidateProfileMutation = useMutation(
    async () => {
      return await callApi(api.createCandidateProfile, {
        team: teamSlug,
        candidate: {
          owner_uuid: ownerUuidParam ?? null,
          company_list_uuids: companyListUuids,
          first_name: candidateProfile.firstName,
          last_name: candidateProfile.lastName,
          email: user.email,
          headline: candidateProfile.headline,
          company: candidateProfile.company,
          title: candidateProfile.title,
          linkedin_url: user.linkedin_url,
          location: candidateProfile.location,
          functions: candidateProfile.functions,
          function_preference: candidateProfile.functionPreference,
          skills: candidateProfile.skills,
          bio: candidateProfile.bio,
          avatar: candidateProfile.avatar,
          level: candidateProfile.level,
          level_preference: candidateProfile.levelPreference,
          funding_stage_experience: candidateProfile.fundingStageExperience,
          stage_preference: candidateProfile.stagePreference,
          opportunity_type: candidateProfile.opportunityType,
          minimum_salary: candidateProfile.minimumSalary,
          what_youre_looking_for: candidateProfile.whatYoureLookingFor,
          is_profile_public: candidateProfile.preferences.isProfilePublic,
          work_status: candidateProfile.workStatus,
          office_preference: candidateProfile.officePreference,
          ideal_company_size: candidateProfile.idealCompanySize,
          work_history: candidateProfile.workHistory.map((entry) => ({
            summary: entry.summary,
            company: entry.company,
            title: entry.title,
            start_at:
              entry.fromMonth !== null && entry.fromYear !== null
                ? getStartOfMonthISO(entry.fromMonth, entry.fromYear)
                : null,
            end_at:
              entry.toMonth !== null && entry.toYear !== null
                ? getEndOfMonthISO(entry.toMonth, entry.toYear)
                : null,
          })),
          education_history: candidateProfile.educationHistory.map((entry) => ({
            summary: entry.summary,
            school: entry.school,
            field_of_study: entry.fieldOfStudy,
            start_at:
              entry.fromMonth !== null && entry.fromYear !== null
                ? getStartOfMonthISO(entry.fromMonth, entry.fromYear)
                : null,
            end_at:
              entry.toMonth !== null && entry.toYear !== null
                ? getEndOfMonthISO(entry.toMonth, entry.toYear)
                : null,
          })),
        },
      })
    },
    {
      onMutate: () => {
        setWorking(true)
      },
      onError: (error) => {
        showError('Error', error)
      },
    },
  )

  const uploadCandidateDocuments = (profileUuid: string) => {
    const documentsFormData = new FormData()
    candidateProfile.documents.forEach((document: File, index: number) => {
      documentsFormData.append(`documents[${index}]`, document)
    })
    return callApi(api.uploadCandidateDocuments, teamSlug, profileUuid, documentsFormData)
  }

  const saveAdvisorMutation = useMutation(() => callApi(api.setOnboarded, teamSlug), {
    onSuccess: () => {
      reloadAdvisorship()
    },
  })

  const containsProfileOrTalentStep =
    steps.includes('personal_info') || steps.includes('talent_profile')

  const handleContinue = async () => {
    if (currentStep === steps.length - 1) {
      if (containsProfileOrTalentStep) {
        try {
          const { candidate_profile: savedProfile } =
            await createCandidateProfileMutation.mutateAsync()
          if (candidateProfile.documents.length > 0) {
            uploadCandidateDocuments(savedProfile.uuid)
              .then(() => {
                finalizeWithSuccessAndShowToast('Profile created successfully', savedProfile.uuid)
              })
              .catch((error) => {
                showError('Failed to upload documents', error)
              })
          } else {
            finalizeWithSuccessAndShowToast('Profile created successfully', savedProfile.uuid)
          }
        } catch (error) {}
      } else {
        await saveAdvisorMutation.mutateAsync()
        location.reload()
      }
    } else {
      setCurrentStep((step) => step + 1)
    }
  }

  const candidateProfileContextVal = React.useMemo(() => {
    return {
      candidateProfile,
      setCandidateProfile,
      updateCandidateProfile: (profile: Partial<CandidateProfileModalInput>) => {
        setCandidateProfile((prev) => ({ ...prev, ...profile }))
      },
    }
  }, [candidateProfile, setCandidateProfile])

  const linkedinRequired = steps.includes('personal_info') || steps.includes('talent_profile')

  if (team === undefined) {
    return null
  }

  if (candidate_needs_to_use_link) {
    return (
      <Background className="w-full h-screen overflow-auto">
        <div ref={parent} className="sm:max-w-[990px] mx-auto mt-10">
          <div className="flex items-center px-1">
            <img src={Logo} alt="Cabal" height="32" width="27" />
            <Typography
              fontWeight={600}
              fontSize="20"
              color="white"
              className="ml-2.5 tracking-tighter"
            >
              Cabal
            </Typography>
          </div>
        </div>

        <Card>
          <div className="flex flex-col items-center gap-6 px-2">
            <Avatar src={team?.logo} name={team?.name} size="64" />
            <Typography fontSize="16" color="fog">
              <i className="fas fa-lock mr-2"></i>
            </Typography>
            <Typography fontSize="16" color="fog">
              Please try again using the invite link you were sent by {team?.name}
            </Typography>
          </div>
        </Card>
      </Background>
    )
  }

  return (
    <CandidateProfileContext.Provider value={candidateProfileContextVal}>
      <Background className="w-full h-screen overflow-auto">
        <div ref={parent} className="sm:max-w-[990px] mx-auto mt-10">
          <div className="flex items-center px-1">
            <img src={Logo} alt="Cabal" height="32" width="27" />
            <Typography
              fontWeight={600}
              fontSize="20"
              color="white"
              className="ml-2.5 tracking-tighter"
            >
              Cabal
            </Typography>
          </div>

          {currentStepName === 'welcome' && <WelcomeMessage onContinue={handleContinue} />}
          {currentStepName === 'name' && (
            <ProfileSetup
              onContinue={handleContinue}
              linkedinRequired={linkedinRequired}
              onBack={() => setCurrentStep(currentStep - 1)}
              candidateOnboarding={true}
            />
          )}

          {currentStepName === 'personal_info' && (
            <CandidatePersonalInfo
              onContinue={handleContinue}
              onBack={() => setCurrentStep(currentStep - 1)}
            />
          )}
          {currentStepName === 'talent_profile' && (
            <TalentProfile
              onContinue={handleContinue}
              onBack={() => setCurrentStep(currentStep - 1)}
              working={working}
            />
          )}
          {currentStepName === 'connections' && (
            <AddConnectionsPage
              onContinue={handleContinue}
              onBack={() => setCurrentStep(currentStep - 1)}
              memberOnboarding={true}
            />
          )}
        </div>
      </Background>
    </CandidateProfileContext.Provider>
  )
}
