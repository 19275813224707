import * as React from 'react'

import { useQuery } from 'react-query'

import AffinitySettingModal from 'containers/Settings/Integration/AffinitySettingModal'
import { useModal } from 'global/Modal'
import { DataCard } from 'ui-components/DataCard'

import api, { callApi } from 'utils/api'
import { ExternalSource } from 'utils/types'

import affinity from '../../../../assets/images/affinity.png'

interface Props {
  teamSlug: string
}

const AffinityIntegrationCard: React.FC<Props> = ({ teamSlug }) => {
  const { showModal } = useModal()

  const openSetupModal = () => {
    showModal(
      (resolve) => <AffinitySettingModal teamSlug={teamSlug} resolve={resolve} />,
      'exchange-setting-MODAL',
    )
  }

  return (
    <>
      <DataCard
        title="Affinity"
        avatarUrl={affinity}
        description={'Sync People, Companies and Lists from Affinity.'}
        secondaryCTA={{
          text: 'Setup',
          onClick: () => {
            openSetupModal()
          },
        }}
      />
    </>
  )
}

export default AffinityIntegrationCard
