import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import cx from 'classnames'
import uniq from 'lodash/uniq'
import moment from 'moment/moment'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { createGlobalState } from 'react-use'
import { AsyncReturnType } from 'type-fest'

import Attachments from 'components/Attachments'
import FocusBannerEmailAuth from 'components/Composer/Sidebar/FocusBannerEmailAuth'
import { AutoSaver } from 'components/ComposerV2/Context/AutoSaver'
import ButtonsModal from 'components/SendMessage/ButtonsModal'
import ButtonsPreview from 'components/SendMessage/ButtonsPreview'
import EmailSnippets from 'components/SendMessage/EmailSnippets'
import GoogleAuthModal from 'components/SendMessage/GoogleAuthModal'
import MapEmailModal from 'components/SendMessage/MapEmailModal'
import MicrosoftAuthModal from 'components/SendMessage/MicrosoftAuthModal'
import PresendCheckFailureModal from 'components/SendMessage/PresendCheckFailureModal'
import { MemoizedRecipientsField as RecipientsField } from 'components/SendMessage/RecipientsField'
import ScheduleSend, { SCHEDULE_DATE_FORMAT } from 'components/SendMessage/ScheduleSend'
import SenderField, { SenderFieldAction } from 'components/SendMessage/SenderField'
import TemplateDropdown from 'components/SendMessage/TemplateDropdown'
import { CcButton } from 'components/SendMessage/styles'
import { getInitialBody, getInitialSubject } from 'components/SendMessage/utils'
import TimeAgo from 'components/TimeAgo'
import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import ShareDraft from 'containers/ShareDraft'
import CabalButton from 'global/CabalButton'
import { OnUpload, TextInput, TextInputChangeEventType, UploadButton } from 'global/Input'
import { useModal } from 'global/Modal'
import { RenderModal, RenderModalNoType } from 'global/Modal/Context'
import Ckeditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import {
  useAdvisors,
  useCurrentUser,
  useTeam,
} from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import ErrorLogger from 'utils/ErrorLogger'
import { HttpStatusCode } from 'utils/HttpStatusCode'
import api, { callApi } from 'utils/api'
import { htmlTextContent } from 'utils/html'
import { advisorMessageRecipientToEmailMessageRecipient } from 'utils/messages'
import Templates from 'utils/templates'
import {
  CurrentUserProfile,
  CurrentUserSettings,
  EmailSnippet,
  EmailTemplate,
  MessageModel,
  MessageRecipient,
  PresendCheckReponse,
  SenderModel,
  UploadBlueprint,
} from 'utils/types'
import { getVariableFieldsUsed } from 'utils/variables'

interface SendMessageV2Props {
  companySlug: string
  onClose?: () => void
  onAfterDelete?: () => void
  message: MessageModel
  presenceListContainer?: HTMLDivElement
  senders: SenderModel[]
  hideToField: boolean
  allowCc: boolean
  allowTemplates: boolean
  allowButtons: boolean
  allowSnippets: boolean
  templates: EmailTemplate[]
  emailSnippets: EmailSnippet[]
  showCreateGroupButton: boolean
  reloadGroups: () => void
  reloadSenders: () => void
  updateSetting: (key: CurrentUserSettings, value: any) => void
  allowScheduledSend?: boolean
  onReloadSnippets: () => Promise<any> // TODO type this...
  introRequestFlow?: boolean
  submitFunc?: (body: string, subject: string) => void
  onSubmit?: (m: MessageModel) => void
  hideModal?: (redirect: boolean) => void
  defaultBody: string
  defaultSubject: string
  defaultTemplate?: keyof typeof Templates
  templateVariables?: Record<string, string>
}

export const useGlobalComposerMessage = createGlobalState<any | undefined>(undefined)

const SendMessageV2: React.FC<SendMessageV2Props> = (props) => {
  const [, setGlobalComposerMessage] = useGlobalComposerMessage()
  const { team } = useTeam(props.companySlug)
  const history = useHistory()
  const { showModal } = useModal()
  const { user, reloadUser } = useCurrentUser()
  const { advisors, reloadAdvisors } = useAdvisors({ teamSlug: props.companySlug })
  const [showCcField, setShowCcField] = useState(props.allowCc)
  const [fromField, setFromField] = useState<string | undefined>()
  const [sender, setSender] = useState<SenderModel | undefined>()
  const [recipientsField, setRecipientsField] = useState(props.message.recipients)
  const [ccField, setCcField] = useState(props.message.cc)
  const [subjectField, setSubjectField] = useState(
    getInitialSubject(props.defaultSubject, props.defaultTemplate, props.templateVariables),
  )
  const [bodyField, setBodyField] = useState(props.defaultBody)
  const [skippedReauth, setSkippedReauth] = useState(false)
  const [scheduledAtField, setScheduledAtField] = useState<string | null>(props.message.schedule_at)
  const [newScheduledAt, setNewScheduledAt] = useState<string | null>(props.message.schedule_at)
  const [notifiedSenderUuids, setNotifiedSenderUuids] = useState(
    props.message.notified_sender_uuids,
  )
  const [buttons, setButtons] = useState(props.message.buttons || [])
  const [attachments, setAttachments] = useState<UploadBlueprint[]>(props.message.attachments || [])
  const [autoSaver, setAutoSaver] = useState<AutoSaver<'updateMessage'> | undefined>(undefined)
  const [lastDraftSave, setLastDraftSave] = useState<Date | null>(null)
  const [autosaveError, setAutosaveError] = useState(false)
  const [workingButton, setWorkingButton] = useState(false)

  useEffect(() => {
    if (!sender || !fromField) {
      let newSender: SenderModel | undefined
      if (user.sender_uuids.includes(props.message.sender.uuid)) {
        newSender = props.senders.find(
          (sender) => user.sender_uuids.includes(sender.uuid) && !sender.use_alias,
        )
      }
      if (!newSender) {
        newSender = props.senders.find((s) => s.uuid === props.message.sender.uuid)
      }

      setSender(newSender)
      setFromField(newSender!.use_alias ? newSender!.alias_email : newSender!.email)
    }
  }, [sender, fromField, props.senders, props.message.sender.uuid])

  function cableCallback(newMessage: Partial<Partial<MessageModel>>) {
    if (newMessage.from && newMessage.from !== fromField) {
      setFromField(newMessage.from)
    }
    if (newMessage.sender && newMessage.sender !== sender) {
      setSender(newMessage.sender)
    }
    if (newMessage.recipients && newMessage.recipients !== recipientsField) {
      setRecipientsField(newMessage.recipients)
    }
    if (newMessage.cc && newMessage.cc !== ccField) {
      setCcField(newMessage.cc)
    }
    if (newMessage.subject && newMessage.subject !== subjectField) {
      setSubjectField(newMessage.subject)
    }
    if (newMessage.body && newMessage.body !== bodyField) {
      setBodyField(newMessage.body)
    }
    if (newMessage.schedule_at && newMessage.schedule_at !== scheduledAtField) {
      setScheduledAtField(newMessage.schedule_at)
    }
    if (
      newMessage.notified_sender_uuids &&
      newMessage.notified_sender_uuids !== notifiedSenderUuids
    ) {
      setNotifiedSenderUuids(newMessage.notified_sender_uuids)
    }
    if (newMessage.buttons && newMessage.buttons !== buttons) {
      setButtons(newMessage.buttons)
    }
    if (newMessage.attachments && newMessage.attachments !== attachments) {
      setAttachments(newMessage.attachments)
    }
  }

  useEffect(() => {
    setGlobalComposerMessage(props.message)
  }, [props.message])

  useEffect(() => {
    if (props.message.uuid && props.message.uuid && user.uuid && !autoSaver) {
      setAutoSaver(
        new AutoSaver({
          teamSlug: props.companySlug,
          objectId: props.message.uuid!,
          userUuid: user.uuid,
          apiName: 'updateMessage',
          onSave: () => {
            setLastDraftSave(new Date())
            setAutosaveError(false)
          },
          setObject: (newMessage) => {
            cableCallback(newMessage)
          },
        }),
      )
    }
  }, [props.companySlug, props.message.uuid, user.uuid, autoSaver])

  const renderButtonsModal: RenderModal = (resolve) => {
    return (
      <ButtonsModal
        show
        onHide={() => resolve()}
        onUpdate={(b) => {
          setButtons(b)
          autoSaver?.sendChange({ buttons: b })
        }}
        inputs={buttons}
      />
    )
  }

  const renderGroupsModal: RenderModal = (resolve) => {
    return (
      <EditGroupsModal
        onHide={() => resolve(false)}
        onSave={() => {
          props.reloadGroups()
        }}
        onDelete={() => {
          props.reloadGroups()
          resolve(false)
        }}
        teamSlug={props.companySlug}
      />
    )
  }
  const onSenderSelect = (sender?: SenderModel | SenderFieldAction) => {
    if (sender === SenderFieldAction.AddGmailEmail) {
      showModal(renderGmailAuthModal, 'gmail-auth-modal')
    } else if (sender === SenderFieldAction.AddMicrosoftEmail) {
      showModal(renderMicrosoftAuthModal, 'microsoft-auth-modal')
      autoSaver?.sendChange({ from: fromField })
    } else if (sender) {
      onSenderOrRecipientsChange({ sender })
      autoSaver?.sendChange({ sender_uuid: sender.uuid, from: fromField })
    }
  }
  const setRecipientsFieldSelect = (v: MessageRecipient[] | null) => {
    onSenderOrRecipientsChange({ recipients: v || [] })
    autoSaver?.sendChange({ recipients: v || [] })
    setGlobalComposerMessage({
      recipients: v,
    })
  }

  const setCcFieldSelect = (v: MessageRecipient[] | null) => {
    onSenderOrRecipientsChange({ cc: v || [] })
    autoSaver?.sendChange({ cc: v || [] })
  }

  const onSenderOrRecipientsChange = (
    localProps: {
      sender?: SenderModel
      recipients?: MessageRecipient[]
      cc?: MessageRecipient[]
      callback?: (
        sender: SenderModel,
        recipients: MessageRecipient[],
        cc: MessageRecipient[],
      ) => void
    } = {},
  ) => {
    const localSender = localProps.sender || sender
    let recipients = localProps.recipients || recipientsField || []
    let cc = localProps.cc || ccField || []

    if (!localSender || !team) return

    if (localSender.type !== 'admin') {
      const currentUserType = user.team && user.team.slug === team.slug ? user.team_role : 'advisor'
      let canSeeSenderEmail = true

      if (localSender.type === 'advisor' && currentUserType !== 'admin') {
        canSeeSenderEmail = false
      }

      let templateSender = ''
      let templateDont = ''
      let templateObjects = ''
      let templateExtra = 'Press OK to remove the recipients'

      const errorMessage = () =>
        `⚠️ ${templateSender} ${templateDont} have permission to send messages to ${templateObjects}. \n\n${templateExtra}`

      if (user.uuid === localSender.uuid) {
        templateSender = 'You'
        templateDont = "don't"
      } else {
        templateSender = `${localSender.name}${canSeeSenderEmail ? ` (${localSender.email})` : ''}`
        templateDont = "doesn't"
      }

      if (currentUserType === 'admin') {
        if ([...recipients, ...cc].some((r) => r.type === 'group')) {
          templateObjects = 'groups'
          if (!confirm(errorMessage())) return
        }

        recipients = recipients
          .filter((r) => r.type !== 'group')
          .map((r) =>
            r.type === 'advisor' ? advisorMessageRecipientToEmailMessageRecipient(r, advisors!) : r,
          )
        cc = cc
          .filter((r) => r.type !== 'group')
          .map((r) =>
            r.type === 'advisor' ? advisorMessageRecipientToEmailMessageRecipient(r, advisors!) : r,
          )
      } else {
        if ([...recipients, ...cc].some((r) => r.type === 'group' || r.type === 'advisor')) {
          if (currentUserType === 'member') {
            templateExtra =
              'Please select an admin in the From field or ask an admin to add these recipients. ' +
              templateExtra
          }
          templateObjects = 'groups and members'
          if (!confirm(errorMessage())) return
        }

        recipients = recipients.filter((r) => r.type !== 'group' && r.type !== 'advisor')
        cc = cc.filter((r) => r.type !== 'group' && r.type !== 'advisor')
      }
    }

    const email = localSender.use_alias ? localSender.alias_email : localSender.email

    setSender(localSender)
    setFromField(email)
    setRecipientsField(recipients)
    setCcField(cc)
    localProps.callback?.(localSender, recipients, cc)
  }

  const senderUuids = user.sender_uuids || []
  const isOwner = sender ? senderUuids.includes(sender.uuid) : false

  const renderGmailAuthModal: RenderModal = (
    resolve,
    customProps = { sendEmail: false, testing: false },
  ) => (
    <GoogleAuthModal
      show
      email_alias_address={user.email_alias_address}
      onHide={() => {
        resolve()
        // this.resetWorkingState()
      }}
      onSkip={async () => {
        await reloadUser()

        setFromField(user.email_alias_address)
        autoSaver?.sendChange({ from: user.email_alias_address })
        setSkippedReauth(true)
        if (customProps.sendEmail) {
          if (customProps.testing) {
            setTimeout(() => onSendTestEmail({ skipGmailAuth: true }))
          } else {
            setTimeout(() => onSubmit({ skipGmailAuth: true }))
          }
        }
        resolve()
      }}
      onAuthorize={async (authorizedEmail) => {
        await reloadUser()
        await props.reloadSenders()

        if (props.senders) {
          const sender = props.senders.find((s) => s.email === authorizedEmail)
          if (sender) {
            setSender(sender)
            autoSaver?.sendChange({ sender_uuid: sender.uuid })
          }
        }
        setFromField(authorizedEmail)
        autoSaver?.sendChange({ from: authorizedEmail })
        if (customProps?.sendEmail) {
          if (customProps?.testing) {
            setTimeout(() => onSendTestEmail({ email: authorizedEmail, sender: sender }))
          } else {
            setTimeout(() => onSubmit({ email: authorizedEmail, sender: sender }))
          }
        }
        resolve()
      }}
    />
  )

  const { mutateAsync: handleNotifySenderApiCall } = useMutation(
    (localProps: { sender: SenderModel; note: string }) =>
      callApi(
        api.messageNotifySender,
        props.message.uuid!,
        localProps.sender?.uuid,
        props.companySlug,
        localProps.note,
      ),
  )

  const { mutateAsync: handleCancelScheduleSendApiCall } = useMutation(() =>
    callApi(api.cancelSend, props.message.uuid!, props.companySlug),
  )

  const renderMicrosoftAuthModal: RenderModal = (resolve) => (
    <MicrosoftAuthModal
      show
      onHide={() => {
        resolve()
      }}
      onAuthorize={async (authorizedEmail) => {
        await reloadUser()
        await props.reloadSenders()

        if (props.senders) {
          const sender = props.senders.find((s) => s.email === authorizedEmail)
          if (sender) {
            setSender(sender)
            autoSaver?.sendChange({ sender_uuid: sender.uuid })
          }
        }
        setFromField(authorizedEmail)
        autoSaver?.sendChange({ from: authorizedEmail })
        resolve()
      }}
    />
  )

  function updateSubject(e: TextInputChangeEventType) {
    setSubjectField(e.target.value)
    autoSaver?.sendChange({ subject: e.target.value })
  }

  const isScheduledSendEnabled = () => {
    return (props.allowScheduledSend ?? false) && user.enable_scheduled_send
  }

  const isScheduledInFuture = () => {
    return newScheduledAt ? moment(newScheduledAt).isAfter(moment()) : false
  }

  const isAlreadyScheduled = () => {
    return scheduledAtField ? moment(scheduledAtField).isAfter(moment()) : false
  }

  const handleCancelScheduleSend = () => {
    handleCancelScheduleSendApiCall().then(() => {
      setScheduledAtField(null)
      setNewScheduledAt(null)
      autoSaver?.sendChange({ schedule_at: null })
    })
  }

  const onAttachmentDelete = (attachment: UploadBlueprint) => {
    const newAttachments = attachments.filter((a) => a.upload_uuid !== attachment.upload_uuid)
    setAttachments(newAttachments)
    autoSaver?.sendChange({ attachments: newAttachments })
  }

  const isSenderNotified = (sender: SenderModel) => {
    return notifiedSenderUuids?.includes(sender.uuid)
  }
  const renderShareDraftModal: (
    resolve: () => void,
    sender: SenderModel,
    notifiedUserUuids: string[],
  ) => React.ReactNode = (resolve, sender, notifiedUserUuids) => (
    <ShareDraft
      onSubmit={(note) => {
        handleNotifySenderApiCall({ sender, note }).then(() => {
          setNotifiedSenderUuids(notifiedUserUuids)
          autoSaver?.sendChange({ notified_sender_uuids: notifiedUserUuids })
          cabalToast({ style: 'success', content: 'Shared with sender!' })
        })
      }}
      to={recipientsField}
      subject={subjectField}
      uuid={props.message.uuid}
      teamSlug={props.companySlug}
      sender={sender}
      onHide={() => {
        resolve()
      }}
    />
  )

  const handleNotifySender = (sender: SenderModel) => {
    if (isSenderNotified(sender)) return

    const notifiedUserUuidsLocal = (notifiedSenderUuids || []).concat(sender.uuid)
    showModal(
      (resolve) => renderShareDraftModal(resolve, sender, notifiedUserUuidsLocal),
      'render_share_draft_modal',
    )
  }
  const renderSubmitButton = (
    incompleteForm: boolean,
    isOwner: boolean,
    iconOnlyButton: boolean,
  ) => {
    const subject = subjectField
    const recipients = recipientsField || []

    let tooltipText = ''

    if (incompleteForm && !subject && recipients.length === 0) {
      tooltipText = 'Add subject and recipient to send'
    } else if (incompleteForm && !subject) {
      tooltipText = 'Add subject to send'
    } else if (recipients.length === 0) {
      tooltipText = 'Add recipient to send'
    }

    let submitText = 'Send'

    const isScheduledSendEnabledLocal = isScheduledSendEnabled() ?? false

    // TODO: Extract schedule send button to a separate component...
    let isScheduledSendButtonDisabled = false

    if (isScheduledSendEnabledLocal) {
      if (!scheduledAtField && newScheduledAt) {
        submitText = `Schedule for ${moment(newScheduledAt).format(SCHEDULE_DATE_FORMAT)}`
        isScheduledSendButtonDisabled = false
      } else if (scheduledAtField && !newScheduledAt) {
        submitText = 'Scheduled'
        isScheduledSendButtonDisabled = true
      } else if (
        scheduledAtField &&
        newScheduledAt &&
        moment(scheduledAtField).isSame(moment(newScheduledAt))
      ) {
        submitText = 'Scheduled'
        isScheduledSendButtonDisabled = true
      } else if (
        scheduledAtField &&
        newScheduledAt &&
        !moment(scheduledAtField).isSame(moment(newScheduledAt))
      ) {
        submitText = `Reschedule for ${moment(newScheduledAt).format(SCHEDULE_DATE_FORMAT)}`
        isScheduledSendButtonDisabled = false
      }
    }

    if (props?.introRequestFlow) {
      submitText = 'Done'
    }

    let submitButton = (
      <CabalButton
        variant={iconOnlyButton ? 'link' : 'primary'}
        className={cx({
          'rounded-r-none': isScheduledSendEnabledLocal,
        })}
        working={workingButton}
        onClick={() => onSubmit()}
        disabled={
          incompleteForm ||
          !isOwner ||
          isScheduledSendButtonDisabled ||
          !subjectField ||
          recipients.length == 0
        }
        data-testid="send-message"
        tooltip={tooltipText}
        leftIcon={
          iconOnlyButton ? (
            <Typography fontSize="18">
              <i className="far fa-paper-plane-top" />
            </Typography>
          ) : undefined
        }
      >
        {!iconOnlyButton && (
          <Typography fontWeight={600} fontSize={'14'}>
            {submitText}
          </Typography>
        )}
      </CabalButton>
    )

    if (sender && (!isOwner || props?.introRequestFlow)) {
      const sharedWithSender = isSenderNotified(sender)
      let canNotifySender = true
      if (
        team?.allow_employees_to_notify_members &&
        user.team?.slug === team?.slug &&
        user.team_role === 'member' &&
        sender.type === 'advisor'
      ) {
        canNotifySender = false
      }

      let tooltip: string | undefined
      if (sharedWithSender) {
        tooltip = `Send ${sender.name} an email notification letting them know this message is ready to send`
      } else if (!canNotifySender) {
        tooltip = `You don’t have permission to share this draft directly. Add teammates by @mentioning them in the comments, or copy the share link.`
      }

      const submitButtonClick = () => {
        if (!props.introRequestFlow) {
          handleNotifySender(sender)
        } else {
          props.hideModal()
        }
      }
      submitButton = (
        <CabalButton
          variant="primary"
          onClick={submitButtonClick}
          disabled={sharedWithSender || !canNotifySender}
          // working={workingState === 'sharing'}
          size="medium"
          leftIcon={<i className="far fa-share"></i>}
          tooltip={tooltip}
          data-testid="composer-share-with-sender-button"
        >
          {!props.introRequestFlow && (
            <>
              Share{sharedWithSender ? 'd' : ''} with{' '}
              {!!props.message.request_uuid ? sender.name || sender.email : 'sender'}
            </>
          )}
          {props.introRequestFlow && submitText}
        </CabalButton>
      )
    }

    if (isScheduledSendEnabledLocal && isOwner && !props?.introRequestFlow) {
      submitButton = (
        <>
          {submitButton}

          <ScheduleSend
            date={
              newScheduledAt || scheduledAtField
                ? moment(newScheduledAt || scheduledAtField).toDate()
                : undefined
            }
            onChangeDate={(scheduleAt) => {
              scheduleAt && setNewScheduledAt(scheduleAt.toISOString())
            }}
            disabled={incompleteForm || !isOwner}
          />
        </>
      )
    }

    return <div className="flex items-center">{submitButton}</div>
  }

  const renderGmailAuthModalWithArgs = (sendEmail: boolean, testing: boolean) => {
    return (resolve) => renderGmailAuthModal(resolve, { sendEmail, testing })
  }
  const handleShowEmailProviderAuthModal = (sendEmail = false, testing = false) => {
    showModal(renderGmailAuthModalWithArgs(sendEmail, testing), 'gmail-auth-modal')
  }

  const handleShowMicrosoftAuthModal = () => {
    return showModal(renderMicrosoftAuthModal, 'microsoft-auth-modal')
  }

  const shouldShowGmailAuthPrompt = async ({
    isSendingTestEmail = false,
    sendEmail = false,
    testEmail = false,
    email = undefined,
  } = {}) => {
    const current_profile = await callApi(api.getCurrentProfile)
    const from = email || fromField
    const sender_emails = current_profile.sender_emails

    if (!from || !sender_emails.includes(from)) {
      handleShowEmailProviderAuthModal(sendEmail, testEmail)
      return true
    }

    const isCabalAliasEmail = from.endsWith('in.getcabal.com')
    if (isCabalAliasEmail) {
      if (isSendingTestEmail && skippedReauth) {
        return false
      } else {
        handleShowEmailProviderAuthModal(sendEmail, testEmail)
        return true
      }
    }

    // check for gmail status
    if (
      user.connected_gmail &&
      user.sender_gmail_users.length > 0 &&
      user.sender_gmail_users.find((u) => u.email === from)
    ) {
      let status: AsyncReturnType<typeof api.checkGmailAuthState>['data']
      let user: CurrentUserProfile | undefined
      try {
        status = (await api.checkGmailAuthState(from)).data
        user = (await reloadUser()).data
      } catch (e) {
        throw e
      }

      const gmail_expired_or_invalid_token = [403, 404].includes(status.token_status)
      const force_gmail_reauth = user?.gmail_auth_statuses[from]?.force_gmail_reauth ?? false
      const has_synced_gmail = user?.gmail_auth_statuses[from]?.has_synced_gmail ?? false

      if (
        force_gmail_reauth ||
        gmail_expired_or_invalid_token ||
        !has_synced_gmail /* && !dismissed_sync_gmail_modal */
      ) {
        handleShowEmailProviderAuthModal(sendEmail, testEmail)
        return true
      }
    }

    return false
  }

  const getMessageParams = (
    saveAsDraft = false,
    overrideRecipients: MessageRecipient[] | undefined = undefined,
    email?: string,
    localSender?: SenderModel,
  ) => {
    const wrapperElement = document.createElement('div')
    wrapperElement.classList.add('rich-text')
    wrapperElement.innerHTML = bodyField!
    return {
      message_uuid: props.message.uuid,
      draft_uuid: props.message.uuid,
      body: saveAsDraft ? bodyField : wrapperElement.outerHTML,
      subject: subjectField,
      attachments,
      send_email: true,
      buttons,
      from: email ? email : fromField,
      sender: localSender ? localSender : sender,
      sender_uuid: sender?.uuid,
      recipients: overrideRecipients ? overrideRecipients : recipientsField,
      cc: ccField,
      draft: isScheduledInFuture() ? true : saveAsDraft,
      schedule_at: isScheduledInFuture() ? newScheduledAt : null,
      current_collaborator_uuid: user.uuid,
      notified_sender_uuids: notifiedSenderUuids,
    }
  }

  const renderMapEmailModal: RenderModalNoType<MessageRecipient[] | undefined> = (resolve) => {
    const variableFieldsUsed = uniq(
      getVariableFieldsUsed(bodyField).concat(getVariableFieldsUsed(subjectField)),
    )

    return (
      <MapEmailModal
        teamSlug={props.companySlug}
        recipients={recipientsField || []}
        variableFieldsUsed={variableFieldsUsed}
        onDone={(r) => {
          setRecipientsField(r)
          autoSaver?.sendChange({ recipients: r })
          resolve(r)
        }}
        show
        onHide={() => {
          resolve(undefined)
        }}
      />
    )
  }

  const handleShowPresendCheckFailureModal = async (presendCheckResponse: PresendCheckReponse) => {
    return await showModal<{ ignoreVars?: boolean; cancel?: boolean } | undefined>(
      (resolve) => (
        <PresendCheckFailureModal presendCheckResponse={presendCheckResponse} onHide={resolve} />
      ),
      'presend-check-failure-modal',
    )
  }
  const preSendCheck = async (args?: { skipVarCheck: boolean }) => {
    if (!team) return

    let preSendCheckResponse
    try {
      preSendCheckResponse = (
        await api.presendCheck(team?.slug, getMessageParams(), {
          skip_var_check: !!args?.skipVarCheck,
        })
      ).data
    } catch (e) {
      // this.resetWorkingState()
      throw e
    }

    if (preSendCheckResponse.name_missing || !!preSendCheckResponse.unknown_vars.length) {
      // there are problems
      const modalResponse = await handleShowPresendCheckFailureModal(preSendCheckResponse)
      if (modalResponse?.cancel) {
        // user wants to cancel the send
        return false
      } else if (modalResponse?.ignoreVars || !modalResponse) {
        // user wants to go ahead
        return true
      }
    }
    // no problems
    return
  }
  const onSubmit = async (
    args: {
      saveAsDraft?: boolean
      skipGmailAuth?: boolean
      skipVarCheck?: boolean
      email?: string
      sender?: SenderModel
    } = {},
  ) => {
    const { saveAsDraft = false, skipGmailAuth = false, skipVarCheck = false } = args
    setWorkingButton(true)
    if (!saveAsDraft) {
      if (
        !team?.permissions?.canMessageAdvisors &&
        !!recipientsField?.find((r) => r.type === 'advisor' || r.type === 'group')
      ) {
        setWorkingButton(false)
        alert('Did you mean to Share Draft? You don’t have permissions to send to this person')
        return
      }

      if (!skipGmailAuth) {
        const shouldShowModalAndStopExecution = await shouldShowGmailAuthPrompt({
          isSendingTestEmail: false,
          sendEmail: true,
          testEmail: false,
          email: args.email,
        })
        if (shouldShowModalAndStopExecution) {
          setWorkingButton(false)
          return
        }
      }
    }

    const presendResult = await preSendCheck({ skipVarCheck })

    if (typeof presendResult === 'boolean') {
      if (presendResult === true) {
        // user wants to go ahead with the send, redo the presend check but ignore vars this time
        onSubmit({ ...args, skipVarCheck: true })
      } else {
        setWorkingButton(false)
        // user wants to cancel
      }
      return
    }

    if (props.submitFunc) {
      props.submitFunc(bodyField, subjectField)
      setWorkingButton(false)
      return
    }

    // show map email prompt if the user has used variables
    const bodyOrSubjectWithVariableFields =
      !!bodyField!.match(/{{[\w_]+}}/) || !!subjectField!.match(/{{[\w_]+}}/)
    let newRecipients: MessageRecipient[] | undefined
    if (
      recipientsField!.some((r) => r.type === 'email') &&
      bodyOrSubjectWithVariableFields &&
      !saveAsDraft
    ) {
      newRecipients = await showModal(renderMapEmailModal, 'map-email-modal')
      if (!newRecipients) {
        setWorkingButton(false)
        return
      }
    }

    const params = getMessageParams(saveAsDraft, newRecipients, args.email, args.sender)
    let newMessage: MessageModel

    try {
      // if the message being edited is a draft and user wants to send it
      // or it is a new message
      if (!saveAsDraft || !props.message.uuid) {
        // either create a sent message or draft message
        if (!saveAsDraft) {
          const textInMessage = htmlTextContent(bodyField!).replace(/\s/g, '')
          if (
            !textInMessage.length &&
            !confirm('Are you sure you want to send an empty message?')
          ) {
            setWorkingButton(false)
            return
          }
        }
        const {
          data: { message: _message },
        } = await api.createMessage(props.companySlug, params)
        if (!props.message.uuid && !saveAsDraft && !params.schedule_at) {
          cabalToast({ style: 'success', content: 'Successfully sent the message' })
        }
        newMessage = _message
      } else {
        // when the user is editing a message and wants to update it
        // either update a draft or update a sent message
        const {
          data: { message: _message },
        } = await api.updateMessage(props.companySlug, props.message.uuid, params)

        newMessage = _message
      }

      if (recipientsField!.some((r) => r.type === 'email' && !!r.value.add_member)) {
        reloadAdvisors()
      }

      if (saveAsDraft) {
        setLastDraftSave(new Date())
      } else {
        setWorkingButton(false)
        props.onSubmit?.(newMessage)
      }
    } catch (e) {
      setWorkingButton(false)
      if ((e as AxiosError)?.response?.status !== HttpStatusCode.BadRequest) {
        cabalToast({
          style: 'error',
          content: 'Something went wrong. Please contact support',
        })
      }

      ErrorLogger.error(e)
    }
  }
  const onSelectTemplate = (template: EmailTemplate) => {
    setSubjectField(template.subject)
    setBodyField(template.body)
    onSenderOrRecipientsChange({ cc: template.cc })
    autoSaver?.sendChange({
      subject: template.subject,
      body: template.body,
      recipients: template.to,
      cc: template.cc,
    })
    const event = new CustomEvent('ckeditor-custom-text', {
      detail: { replace: true, body: template.body, messageUuid: props.message.uuid },
    })
    document.dispatchEvent(event)
  }
  const onSendTestEmail = async (
    args: {
      skipGmailAuth?: boolean
      skipVarCheck?: boolean
      email?: string
      sender?: SenderModel
    } = {},
  ) => {
    const { skipGmailAuth = false, skipVarCheck = false } = args

    const presendResult = await preSendCheck({ skipVarCheck })

    if (typeof presendResult === 'boolean') {
      if (presendResult === true) {
        // user wants to go ahead with the send, redo the presend check but ignore vars this time
        onSubmit({ ...args, skipVarCheck: true })
      } else {
        // user wants to cancel
      }
      return
    }

    if (!skipGmailAuth) {
      const shouldShowModalAndStopExecution = await shouldShowGmailAuthPrompt({
        isSendingTestEmail: true,
        sendEmail: true,
        testEmail: true,
        email: args.email,
      })
      if (shouldShowModalAndStopExecution) {
        return
      }
    }

    const params = getMessageParams(false, undefined, args.email, args.sender)
    api.sendTestEmail(params).then(() => {
      cabalToast({ style: 'success', content: `Successfully sent the test mail` })
    })
  }

  const onAttachmentUpload: OnUpload = (uuid, url, file) => {
    const localAttachments = attachments
    localAttachments.push({
      uuid: uuid,
      upload_uuid: uuid,
      api_url: url,
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
    })
    setAttachments(localAttachments)
    autoSaver?.sendChange({ attachments: localAttachments })
  }

  return (
    <>
      <div className="lg:hidden flex justify-end items-center gap-2">
        {renderSubmitButton(!subjectField, isOwner, true)}

        {props.onClose && (
          <Typography
            onClick={props.onClose}
            fontSize="18"
            color={'gray'}
            className="cursor-pointer"
          >
            <i className="far fa-times" />
          </Typography>
        )}
      </div>

      <FocusBannerEmailAuth
        showCta
        onGoogleAuthClick={handleShowEmailProviderAuthModal}
        onMicrosoftAuthClick={handleShowMicrosoftAuthModal}
      />

      <div className="flex">
        {team && (
          <SenderField
            senders={props.senders}
            from={fromField}
            recipients={props.message.recipients}
            onSelect={onSenderSelect}
            team={team}
          />
        )}
        {(props.allowTemplates || props.allowSnippets) && (
          <div className="right-actions ml-2 flex">
            {props.allowSnippets && (
              <EmailSnippets
                snippets={props.emailSnippets}
                onReloadSnippets={props.onReloadSnippets}
                // onSelect={handleSelectSnippet}
                onSelect={() => console.log('handleSelectSnippet')}
              />
            )}

            {props.allowTemplates && team && (
              <TemplateDropdown
                team={team}
                templates={props.templates}
                onSelectTemplate={onSelectTemplate}
                // hideModal={onClose}
              />
            )}

            {/* TODO this is disabled ATM. */}
            {/* {window.DRAFT_ON_BEHALF && message_uuid && (
                  <DraftOnBehalf companySlug={companySlug} messageUuid={message_uuid} />
                )} */}
          </div>
        )}
      </div>

      {!props.hideToField && (
        <div className="flex align-middle" data-intercom-target="messages_to_field">
          <div className="flex-1">
            <RecipientsField
              willMailMerge={true}
              selected={recipientsField}
              teamSlug={props.companySlug}
              allowGroups={true}
              sender={sender}
              label={'To'}
              allowCustomEmail={true}
              onSelect={setRecipientsFieldSelect}
              // onSelect={() => console.log('setRecipientsField')}
              data-intercom-target="messages_to_field"
              fromRequest={true}
            />
          </div>

          {!showCcField && (
            <CcButton
              fontSize="12"
              onClick={() => setShowCcField(!showCcField)}
              component="button"
              lineHeight="1"
              color={'fog'}
              data-testid="cc-btn"
            >
              Cc
            </CcButton>
          )}

          {team && props.showCreateGroupButton && (
            <CabalButton
              variant="secondary"
              leftIcon={<i className="far fa-users" />}
              onClick={() => {
                showModal(renderGroupsModal, 'groups-modal')
                props.updateSetting('hide_create_group_button', true)
              }}
              className="ml-1 md:ml-2"
            >
              Create Group
            </CabalButton>
          )}
        </div>
      )}
      {showCcField && (
        <div className="flex align-middle" data-intercom-target="messages_to_field">
          <div className="flex-1">
            <RecipientsField
              willMailMerge={true}
              selected={ccField}
              teamSlug={props.companySlug}
              onSelect={setCcFieldSelect}
              label="Cc"
              allowGroups={false}
              sender={sender}
              allowCustomEmail
              type="cc"
            />
          </div>
          {showCcField && (
            <CcButton
              onClick={() => setShowCcField(!showCcField)}
              component="button"
              lineHeight="1"
            >
              <i className="fas fa-times" />
            </CcButton>
          )}
        </div>
      )}
      <div>
        <TextInput
          data-testid="send-message-subject-field"
          fontSize="14px"
          labelPosition="left"
          label="Subject"
          type="text"
          value={subjectField}
          onChange={(e) => updateSubject(e)}
        />
      </div>
      <Ckeditor
        windowKey="send-message"
        message_uuid={props.message.uuid}
        placeholder="Compose email"
        allowVariables
        allowSignature
        team={team}
        value={getInitialBody(
          props.defaultBody,
          props.defaultTemplate,
          props.templateVariables,
          user.email_signature,
        )}
        companySlug={props.companySlug}
        presenceListContainer={props.presenceListContainer}
        autoSave={(b) => {
          setBodyField(b)
          autoSaver?.sendChange({ body: b })
        }}
        user={user}
      />
      {buttons && buttons.length > 0 && <ButtonsPreview inputs={buttons} />}
      {/*{widgetPreview}*/}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <UploadButton
            maxFileSize={5}
            onUpload={onAttachmentUpload}
            text=""
            triggerProps={{
              variant: 'secondary',
              leftIcon: <i className="far fa-paperclip" />,
              size: 'small',
              tooltip: 'Attachments',
            }}
          />
          {props.allowButtons && (
            <div className={'ml-2'}>
              <CabalButton
                leftIcon={<i className="far fa-plus-square" />}
                onClick={() => showModal(renderButtonsModal, 'buttons-modal')}
                variant="secondary"
                size="small"
                tooltip="Edit Buttons"
              />
            </div>
          )}
          {props.message.uuid && (
            <CabalButton
              leftIcon={<i className="far fa-trash" />}
              variant="tertiary"
              onClick={() => {
                if (confirm('Are you sure you want to delete this draft?')) {
                  api.deleteMessage(props.companySlug, props.message.uuid).then(() => {
                    props.onAfterDelete?.()
                    props.onClose?.()
                  })
                }
              }}
              size="small"
              tooltip="Delete draft"
            />
          )}
        </div>
        <div className="flex items-center gap-3">
          {lastDraftSave && (
            <Typography
              fontSize="14"
              color="fog"
              // fontWeight={500}
              className="hidden md:block"
              component="div"
            >
              Draft saved <TimeAgo datetime={lastDraftSave} />
            </Typography>
          )}
          {autosaveError && (
            <div className="hidden sm:block">
              <Typography color="yellow_bold" className="mx-3" component="div" fontSize="12">
                Draft autosave error
              </Typography>
              <CabalButton
                variant="tertiary"
                onClick={() => onSubmit({ saveAsDraft: true })}
                // working={workingState === 'drafting'}
              >
                <Typography fontWeight={400} fontSize={'14'}>
                  Save draft
                </Typography>
              </CabalButton>
            </div>
          )}
          {isOwner && (
            <div className="hidden sm:block">
              <CabalButton
                variant="tertiary"
                onClick={() => onSendTestEmail()}
                data-testid="send-test-email-button"
              >
                <Typography fontWeight={400} fontSize={'14'}>
                  Send me a test email
                </Typography>
              </CabalButton>
            </div>
          )}

          <div className="hidden lg:block">{renderSubmitButton(!subjectField, isOwner, false)}</div>
        </div>
      </div>
      {isScheduledSendEnabled() && isAlreadyScheduled() && (
        <div className="flex items-center justify-end gap-x-4 pt-2">
          <Typography className="text-sm" fontSize="13" color="fog_rain">
            Scheduled for: {moment(scheduledAtField).format(SCHEDULE_DATE_FORMAT)}
          </Typography>

          {isOwner && (
            <CabalButton variant="secondary" size="small" onClick={handleCancelScheduleSend}>
              Cancel send
            </CabalButton>
          )}
        </div>
      )}
      {attachments && attachments.length > 0 && (
        <div className="pt-2">
          <Attachments attachments={attachments} onDelete={onAttachmentDelete} />
        </div>
      )}
    </>
  )
}

export default SendMessageV2
