import React, { ReactNode, useCallback } from 'react'

import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'

import cx from 'classnames'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

import Typography from '../Typography'
import { useModal } from './Context'

const Header = styled.div<{ noPadding: boolean }>`
  ${tw`sticky top-0 py-2 z-10 flex justify-between -mx-6 mb-4 rounded-t-lg items-center`}
  ${({ noPadding }) => (!noPadding ? tw`-mx-6` : tw`mx-0`)}

  background: ${({ theme }) => theme.colors.shadow};
  border-bottom: ${({ theme }) => theme.border};
`

const Actions = styled.div`
  ${tw`flex sticky bottom-0 justify-between py-4 z-10 -mx-6 rounded-b-lg`}
  background: ${({ theme }) => theme.colors.shadow};
`

const StyledDialogContent = styled(DialogContent)<{ noPadding: boolean; animateOnMount: boolean }>`
  ${tw`py-0 relative rounded-lg`}
  ${({ noPadding }) => (!noPadding ? tw`px-6` : tw`px-0`)}

  background-color: ${({ theme }) => theme.colors.shadow};
  color: ${({ theme }) => theme.colors.primary};
  width: 98vw;
  -webkit-font-smoothing: antialiased;
  overflow: auto;

  @media (min-width: 650px) {
    width: 600px;
  }

  @media (min-width: 1024px) {
    width: 700px;
  }

  ${({ animateOnMount }) => animateOnMount && `animation: modalZoom 0.1s;`}
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`

type OnHide = (val?: boolean | undefined) => void
export interface OnHideProp {
  onHide: OnHide
}

interface Props {
  show: boolean
  header?: ReactNode
  onHide: OnHide
  canClose?: boolean
  hasChildModals?: boolean
  leftActions?: ReactNode
  rightActions?: ReactNode
  centerActions?: ReactNode
  ignoreEscapeBlock?: boolean
  dangerouslyBypassFocusLock?: boolean
  noPadding?: boolean
  animateOnMount?: boolean
  children: ReactNode
  className?: string
}

const Modal: React.FC<Props> = ({
  show,
  header,
  onHide,
  leftActions,
  rightActions,
  centerActions,
  children,
  className,
  canClose = true,
  hasChildModals = false,
  ignoreEscapeBlock = false,
  dangerouslyBypassFocusLock = false,
  noPadding = false,
  animateOnMount = false,
}) => {
  const { escapeBlocked, bypassFocusLock } = useModal()

  const closeModal = useCallback(() => {
    if (escapeBlocked && !ignoreEscapeBlock) return

    const modalPortals = document.querySelectorAll('.DialogOverlay')
    if (hasChildModals && modalPortals.length > 1) return

    show && canClose && onHide()
  }, [show, canClose, onHide, hasChildModals, ignoreEscapeBlock])

  const handleKeyPress = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        closeModal()
      }
    },
    [closeModal],
  )

  React.useEffect(() => {
    document.addEventListener('keypress', handleKeyPress, false)

    return () => {
      document.removeEventListener('keypress', handleKeyPress, false)
    }
  }, [handleKeyPress])

  return (
    <StyledDialogOverlay
      className="DialogOverlay"
      isOpen={show}
      dangerouslyBypassFocusLock={bypassFocusLock || dangerouslyBypassFocusLock}
      onDismiss={(e) => {
        if (e?.nativeEvent instanceof MouseEvent) return
        closeModal()
      }}
    >
      <StyledDialogContent
        animateOnMount={animateOnMount}
        noPadding={noPadding}
        className={className}
      >
        {header && (
          <Header noPadding={noPadding}>
            <div className="pl-6 w-full">
              {typeof header === 'string' ? (
                <Typography fontSize="12" fontWeight={600} lineHeight="15px">
                  {header}
                </Typography>
              ) : (
                header
              )}
            </div>

            {canClose && (
              <Typography
                onClick={() => onHide()}
                className="cursor-pointer focus:ring-1 rounded mr-4 ml-2 px-2"
                component="button"
                color="fog_rain"
                fontSize="16"
                lineHeight={'10px'}
                tabIndex={-1}
              >
                <i className="far fa-times"></i>
              </Typography>
            )}
          </Header>
        )}

        {!header && canClose && (
          <Typography
            onClick={() => onHide()}
            className="absolute right-4 top-4 cursor-pointer focus:ring-1 rounded"
            component="button"
            color="fog_rain"
            fontSize="16"
            lineHeight={'10px'}
            tabIndex={-1}
          >
            <i className="far fa-times"></i>
          </Typography>
        )}

        {children}
        {(leftActions || centerActions || rightActions) && (
          <>
            <Actions>
              <div className={cx('mx-6', { 'mr-12': leftActions && rightActions })}>
                {leftActions}
              </div>
              <div className={cx('mx-6', { 'mr-12': centerActions && rightActions })}>
                {centerActions}
              </div>
              <div className="mx-6">{rightActions}</div>
            </Actions>
          </>
        )}
      </StyledDialogContent>
    </StyledDialogOverlay>
  )
}

export default Modal
export { useModal }
