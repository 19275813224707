/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CTAComponent from 'containers/RequestCenter/CTAComponent'
import { EditableAmountCell } from 'containers/RequestCenter/EditableAmountCell'
import { EditableStatusCell } from 'containers/RequestCenter/EditableStatusCell'
import InfoCard from 'containers/RequestCenter/InfoCard'
import SkeletalRows from 'containers/RequestCenter/SkeletalRows'
import { TD_IDS, clearbitUrl } from 'containers/RequestCenter/constants'
import {
  IFacilitator,
  IRequestor,
  ITarget,
  StatusType,
} from 'containers/RequestCenter/types/ITypes'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import { Team } from 'utils/types'

import {
  IPaginationExtended,
  IRedirectToIntroDetailsPage,
  ITableDataObject,
  ITableDataObjectArr,
} from './types/ITypes'
import { IRequestsData } from './types/ITypes'
import { prepareStatusForState, statusIconMappingFunc } from './utils'

interface IProps {
  requestCenterData: IRequestsData[] | null
  pagination: IPaginationExtended | null
  introPage: number | null
  introStatus: string[] | null
  introName: string | null
  isMouseOverTable: boolean
  setOrder: ({ order_by, direction }: { order_by: string; direction: string }) => void
  refetch: () => void
}

const TableContainer = styled.div`
  ${tw`mb-8 relative z-0`}// Use relative positioning
`
const StyledTh = styled.th<{ width?: string }>`
  ${tw`p-2 font-normal text-sm sticky top-[60px] z-10`}
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
  font-weight: 500;
  border-top: 1px solid ${({ theme }) => `${theme.colors.rain}50`};
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.rain}50`};
  width: ${({ width }) => width};
`
export const StyledTd = styled.td<{ width?: string; hideTruncate?: boolean }>`
  ${tw`font-normal text-sm px-2 py-3`}
  ${({ hideTruncate }) => !hideTruncate && tw`truncate`}
  color: ${({ theme }) => theme.colors.fog};
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.rain}50`};
  width: ${({ width }) => (width ? width : '150px')};
`

const getNameWithTooltip = (user: ITarget | IFacilitator | IRequestor, porfolioEnabled = false) => {
  const [firstName, lastName] = user?.name?.split(' ') || ['', '']
  return user && user.name ? (
    <InfoCard
      label={`${firstName}${lastName ? ` ${lastName.charAt(0)}.` : '.'}`}
      user={user}
      portfolioEnabled={porfolioEnabled}
    />
  ) : (
    '-'
  )
}

const getTargetNamePortfolio = (
  user: ITarget | IFacilitator | IRequestor,
  companyName: string,
  companyWebsite: string | null,
) => {
  const nameWithTooltip = getNameWithTooltip(user, true)
  const src = companyWebsite === null ? '' : `${clearbitUrl}${companyWebsite}`
  return (
    <>
      <Avatar className="flex-shrink-0 mr-2" round size={'24'} src={src} name={companyName} />
      <Typography color="primary" className="mr-2">
        {companyName}
      </Typography>
      {nameWithTooltip}
    </>
  )
}

const getNameFormat = (user: ITarget | IFacilitator | IRequestor, companyName: string) => {
  const nameWithTooltip = getNameWithTooltip(user)
  return (
    <>
      {nameWithTooltip} {companyName}
    </>
  )
}

const prepareTableData = (
  requestCenterData: IRequestsData[] | null,
  tdObjectArr: ITableDataObjectArr,
  pagination: IPaginationExtended | null,
  team: Team,
): ITableDataObjectArr => {
  const statusIconMapping: StatusType = statusIconMappingFunc(team)
  requestCenterData?.forEach((requestData, index) => {
    if (pagination?.current_count === 0) {
      return ''
    }
    const {
      target,
      facilitator,
      requestor,
      initiator,
      intro_request_uuid: introRequestUuid,
      status,
      last_change,
      amount,
    } = requestData

    const key = `${target?.name}-${index}`
    const requestorName = team!.enable_portfolio
      ? getNameFormat(requestor, requestor?.company_name)
      : getNameFormat(requestor, '')
    const targetName = team!.enable_portfolio
      ? getNameFormat(target, target?.company_name)
      : getTargetNamePortfolio(target, target?.company_name, target?.company_website)
    const tdObject: ITableDataObject = {
      [`tr-${key}`]: {
        target: {
          name: targetName,
        },
        facilitator: {
          name: getNameFormat(facilitator, facilitator?.company_name),
        },
        requestor: {
          name: requestorName,
        },
        initiator: {
          name: initiator?.name,
        },
        introRequestUuid: introRequestUuid,
        status: {
          label: statusIconMapping[status]['label'],
          icon: statusIconMapping[status]['icon'],
          text_color: statusIconMapping[status]['text_color'],
          bg_color: statusIconMapping[status]['bg_color'],
        },
        lastChange: {
          label: last_change,
        },
        amount: {
          label: amount,
        },
      },
    }
    tdObjectArr.push(tdObject)
  })
  return tdObjectArr
}

const redirectToIntroDetailsPage = ({
  history,
  introRequestUuid,
  introPage,
  statusForState,
  introName,
}: IRedirectToIntroDetailsPage) => {
  history.push({
    pathname: `intro-requests/${introRequestUuid}`,
    state: { introPage, introStatus: statusForState, introName },
  })
}

export function BasicTable({
  requestCenterData,
  pagination,
  introPage,
  introStatus,
  introName,
  isMouseOverTable,
  setOrder,
  refetch,
}: IProps) {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const [sortColumn, setSortColumn] = useState<string>('updated_at')
  const [sortAsc, setSortAsc] = useState<boolean>(false)
  const [editingRow, setEditingRow] = useState<string | null>(null)
  const [editableColumns, setEditableColumns] = useState<{ [key: string]: boolean }>({})
  const [tableRowData, setTableRowData] = useState<ITableDataObjectArr | []>([])
  const statusForState = prepareStatusForState(introStatus)
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const handleThClick = (e: React.MouseEvent) => {
    sortColumn == e.currentTarget.id
      ? setSortAsc((prev) => !prev)
      : setSortColumn(e.currentTarget.id)
  }

  useEffect(() => {
    setOrder({ order_by: sortColumn, direction: sortAsc ? 'asc' : 'desc' })
  }, [setOrder, sortAsc, sortColumn])

  const displayArrow = (thId: string) => {
    return sortColumn != thId ? (
      <i className="far fa-sort ml-2" />
    ) : sortAsc ? (
      <i className="fas fa-sort-up ml-2" />
    ) : (
      <i className="fas fa-sort-down ml-2" />
    )
  }

  const tdObjectArr: ITableDataObjectArr = []
  const history = useHistory()

  useEffect(() => {
    setTableRowData(prepareTableData(requestCenterData, tdObjectArr, pagination, team!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCenterData, sortColumn, sortAsc, pagination]) // Dependency array includes requestCenterData

  return (
    <TableContainer onMouseLeave={() => setEditingRow(null)}>
      <table
        className="w-full"
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0px',
          tableLayout: 'fixed',
        }}
      >
        <thead className="text-left">
          {/* <StyledTh width="30px">
            <CheckBox />
          </StyledTh> */}
          <StyledTh width={team!.enable_portfolio ? '150px' : '190px'} id="target">
            Target
          </StyledTh>
          <StyledTh width={team!.enable_portfolio ? '150px' : '120px'} id="requestor">
            Requestor
          </StyledTh>
          <StyledTh width={team!.enable_portfolio ? '150px' : '180px'} id="facilitator">
            Facilitator
          </StyledTh>
          {/* <StyledTh
            width="150px"
            id="initiator"
            onClick={(e: React.MouseEvent) => handleThClick(e)}
          >
            Initiator {displayArrow(TD_IDS.initiator)}
          </StyledTh> */}
          <StyledTh
            width={team!.enable_portfolio ? '120px' : '105px'}
            id="status"
            onClick={(e: React.MouseEvent) => handleThClick(e)}
            className="hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f] cursor-pointer"
          >
            Status {displayArrow(TD_IDS.status)}
          </StyledTh>
          <StyledTh
            width={team!.enable_portfolio ? '120px' : '90px'}
            id="amount"
            onClick={(e: React.MouseEvent) => handleThClick(e)}
            className="hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f] cursor-pointer"
          >
            Amount {displayArrow(TD_IDS.amount)}
          </StyledTh>

          <StyledTh
            width="120px"
            id="updated_at"
            onClick={(e: React.MouseEvent) => handleThClick(e)}
            className="hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f] cursor-pointer"
          >
            Updated {displayArrow(TD_IDS.lastChange)}
          </StyledTh>
          <StyledTh width="30px"></StyledTh>
        </thead>
        <tbody>
          {!requestCenterData && <SkeletalRows rowCount={10} />}

          {tableRowData!.map((tdObject: ITableDataObject) => {
            const key = Object.keys(tdObject)[0]
            const { target, facilitator, requestor, introRequestUuid, status, lastChange, amount } =
              tdObject[key]

            const introRequestDetails = requestCenterData?.find(
              (requestData) => requestData.intro_request_uuid === introRequestUuid,
            )

            return (
              <tr
                key={key}
                className="cursor-pointer transition ease-in hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f]"
                onClick={() =>
                  redirectToIntroDetailsPage({
                    history,
                    introRequestUuid,
                    introPage,
                    statusForState,
                    introName,
                  })
                }
                onMouseEnter={() => {
                  setEditingRow(key)
                }}
              >
                {/* <StyledTd width="30px">
                  <CheckBox />
                </StyledTd> */}
                <StyledTd>
                  <span className="">{target?.name}</span>
                </StyledTd>
                <StyledTd>
                  <span className="">{requestor?.name}</span>
                </StyledTd>
                <StyledTd>
                  <span className="">{facilitator?.name}</span>
                </StyledTd>
                {/* <StyledTd>
                  <span className="">{initiator?.name}</span>
                </StyledTd> */}
                <StyledTd
                  style={{ textOverflow: 'unset' }}
                  onClick={(e: React.MouseEvent) => {
                    if (e) {
                      e.stopPropagation()
                    }
                  }}
                >
                  {editingRow === key ? (
                    <EditableStatusCell
                      introRequestUuid={introRequestUuid}
                      setTableRowData={setTableRowData}
                      cellKey={key}
                      currentStatus={status}
                      setEditingRow={setEditingRow}
                    />
                  ) : (
                    <div
                      className={`flex w-fit text-xs items-center gap-2 ${status.bg_color} ${status.text_color} px-2 py-0.5 rounded`}
                    >
                      {status.label}
                    </div>
                  )}
                </StyledTd>
                <StyledTd
                  width="120px"
                  onClick={(e: React.MouseEvent) => {
                    if (e) {
                      e.stopPropagation()
                    }
                  }}
                >
                  {editingRow === key ? (
                    <>
                      {!editableColumns[`${key}amount`] && (
                        <div
                          className="flex justify-between"
                          onClick={(e) => {
                            e.stopPropagation()
                            setEditableColumns({ ...editableColumns, [`${key}amount`]: true })
                          }}
                        >
                          <Typography component="p">
                            {amount?.label && amount?.label.toString() !== '-' ? (
                              <>$ {amount.label}</>
                            ) : (
                              '-'
                            )}
                          </Typography>
                          <Typography component="p">
                            <i className="far fa-pencil-alt fa-xs cursor-pointer" />
                          </Typography>
                        </div>
                      )}

                      {editableColumns[`${key}amount`] && (
                        <EditableAmountCell
                          introRequestUuid={introRequestUuid}
                          value={amount?.label || ''}
                          setTableRowData={setTableRowData}
                          cellKey={key}
                          setEditingRow={setEditingRow}
                          editableColumns={editableColumns}
                          setEditableColumns={setEditableColumns}
                        />
                      )}
                    </>
                  ) : (
                    <Typography component="p">
                      {amount?.label && amount?.label.toString() !== '-' ? (
                        <>$ {amount.label}</>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  )}
                </StyledTd>
                <StyledTd className="w-[130px]]">
                  <div>{lastChange.label}</div>
                </StyledTd>
                <StyledTd
                  width="30px"
                  className="relative"
                  hideTruncate={true}
                  onClick={(e: React.MouseEvent) => {
                    if (e) {
                      e.stopPropagation()
                    }
                  }}
                >
                  {introRequestDetails && (
                    <CTAComponent
                      status={status.label}
                      isEditing={editingRow === key}
                      introRequestDetails={introRequestDetails}
                      refetch={refetch}
                    />
                  )}
                </StyledTd>
              </tr>
            )
          })}
        </tbody>
      </table>
    </TableContainer>
  )
}
