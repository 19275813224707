import React, { useState } from 'react'

import { DataCard } from 'ui-components/DataCard'
import Modal, { useModal } from 'global/Modal'
import { TextInput } from 'global/Input'
import { useMutation, useQuery } from 'react-query'
import axios, { AxiosError } from 'axios'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { cabalToast } from 'ui-components/Toast'

import mailIcon from '../../../../assets/images/ms_exchange_2019.png'
import { useCurrentUser } from 'store/hooks'

const ExchangeSettingModal: React.VFC<{ onHide: () => void }> = ({ onHide }) => {
  const { reloadUser } = useCurrentUser()
  const [server, setServer] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [passphrase, setPassphrase] = useState<string>()

  const { isFetching } = useQuery(['ms_exchange_2019'], () => axios.get('/auth/ms_exchange_2019'), {
    onSuccess: ({ data }) => {
      setServer(data.server)
      setEmail(data.email)
      setPassphrase(data.passphrase)
    },
  })

  const setms_exchange_2019 = useMutation(
    ['ms_exchange_2019'],
    () =>
      axios.post('/auth/ms_exchange_2019', {
        server,
        email,
        passphrase,
      }),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Successfully saved Microsoft Exchange 2019 settings',
        })
        reloadUser()
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === 403) {
          cabalToast({ style: 'error', content: 'Invalid credentials' })
        }
      },
    },
  )

  return (
    <Modal
      show
      onHide={onHide}
      header="Provide Microsoft Exchange 2019 access details"
      rightActions={
        <CabalButton
          disabled={!server || !email || !passphrase}
          onClick={() => setms_exchange_2019.mutate()}
          working={setms_exchange_2019.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      {isFetching && <Loading />}
      {!isFetching && (
        <>
          <div className={'mt-3'}></div>
          <TextInput
            label="Server (eg. mail.example.com)"
            className="mb-2"
            value={server}
            onChange={(e) => setServer(e.currentTarget.value)}
          />
          <TextInput
            label="Email (eg. mail@example.com)"
            name="exchange-email"
            className="mb-2"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <TextInput
            label="Passphrase"
            name="exchange-passphrase"
            className="mb-2"
            autoComplete="off"
            value={passphrase}
            onChange={(e) => setPassphrase(e.currentTarget.value)}
            type="password"
          />
        </>
      )}
    </Modal>
  )
}

const ExchangeIntegrationCard: React.FC = () => {
  const { showModal } = useModal()

  const openSetupModal = () => {
    showModal((resolve) => <ExchangeSettingModal onHide={resolve} />, 'exchange-setting-modal')
  }

  return (
    <>
      <DataCard
        title="Microsoft Exchange 2019"
        avatarUrl={mailIcon}
        description={'Send messages directly through your Microsoft Exchange 2019 server.'}
        secondaryCTA={{
          text: 'Setup',
          onClick: () => {
            openSetupModal()
          },
        }}
      />
    </>
  )
}

export default ExchangeIntegrationCard
