import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import cx, { length } from 'classnames'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'
import { useQuery } from 'react-query'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { getFormattedStage } from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { SalesFilterTypes } from 'containers/ListIndexView/SalesList/type'
import { portfolioEmployeeSizeOptions } from 'containers/ListView/EditPortfolioCompanyModal/CompanyInfo'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

interface Props {
  disabled?: boolean
  filters: any
  setFilters: (filters: SalesFilterTypes) => void
  // ownedCompanyTags?: any
  setSelectedItems: (selectedItems: any[]) => void
}

const upcastFilters = memoizeOne((filters: SalesFilterTypes) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const portfolioStages = filters.portfolio_stages
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sizes = filters.size
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const sort = filters.sort
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: SalesFilterTypes = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const sizes = filters['size'] as string[] | null
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolioStages = filters['portfolio_stages'] as string[] | null
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sort = filters['sort'] as string | null
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const SalesListFilters: React.FC<Props> = ({
  disabled,
  filters,
  setFilters,
  // ownedCompanyTags,
  setSelectedItems,
}) => {
  const teamSlug = useTeamSlug()
  const { companyListData, salesCompanies, salesPeople } = useSalesListContext()

  const item = salesPeople

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  // const tagOptions = ownedCompanyTags?.tags || []
  // const stageOptions = ownedCompanyTags?.stages || []

  const adminView = !!isAdminOrEmployee && !!companyListData.owned
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'sort',
        type: 'dropdown',
        label: (
          <Typography>
            <i className="far fa-sort fa-sm mr-1" />
            Sort
          </Typography>
        ),
        labelStr: 'Sort',
        options: [
          { label: 'Name (A-Z)', value: 'name-asc' },
          { label: 'Name (Z-A)', value: 'name-desc' },
        ],
      },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  return (
    <div
      ref={parent}
      className={cx('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {adminView && !filters.member_view && (
              <CheckBox
                onChange={(e) => {
                  setSelectedItems(e.target.checked ? item : [])
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={filters.member_view}
                onCheckedChange={(e) => setFilters({ ...filters, member_view: e })}
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
      )}
    </div>
  )
}

export default SalesListFilters
