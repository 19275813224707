import React, { useState } from 'react'

import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'

import { Team } from 'utils/types'

interface Props {
  onHide: () => void
  team: Team
  onSubmit: (
    reason?: 'portfolio-founder' | 'recruiting' | 'portfolio-company' | 'job-seeker' | 'partner',
  ) => void
}

const ReasonToJoinModal = ({ onHide, team, onSubmit }: Props) => {
  const { name } = team
  const [reason, setReason] = useState<
    'portfolio-founder' | 'recruiting' | 'portfolio-company' | 'job-seeker' | 'partner'
  >()

  const handleSave = () => {
    onSubmit(reason)
    onHide()
  }

  return (
    <Modal
      header={`Request to join ${name}?`}
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton onClick={handleSave} variant="primary">
          Send join request
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <Typography fontSize="14">
          Help {name} give you access to the right resources by specifying your relationship.
        </Typography>

        <div className="flex gap-2 flex-wrap">
          <PillV2
            title={`I’m a ${name} Portfolio Founder`}
            onClick={() => setReason('portfolio-founder')}
            active={reason === 'portfolio-founder'}
          />
          <PillV2
            title={`I’m looking for candidates`}
            onClick={() => setReason('recruiting')}
            active={reason === 'recruiting'}
          />
          <PillV2
            title={`I work at a ${name} Portfolio company`}
            onClick={() => setReason('portfolio-company')}
            active={reason === 'portfolio-company'}
          />
          <PillV2
            title={`I’m looking for new job opportunities`}
            onClick={() => setReason('job-seeker')}
            active={reason === 'job-seeker'}
          />
          <PillV2
            title={`I’m a partner to ${name}`}
            onClick={() => setReason('partner')}
            active={reason === 'partner'}
          />
        </div>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ReasonToJoinModal
