import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import NoResultsPage from 'containers/ListIndexView/Components/NoResultsPage'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourcesListBulkActions from 'containers/ListIndexView/ResourcesList/ResourcesListBulkActions'
import ResourcesListCTAs from 'containers/ListIndexView/ResourcesList/ResourcesListCTAs'
import ResourcesListEmptyState from 'containers/ListIndexView/ResourcesList/ResourcesListEmptyState'
import ResourcesListFilters from 'containers/ListIndexView/ResourcesList/ResourcesListFilters'
import ResourcesListHeader from 'containers/ListIndexView/ResourcesList/ResourcesListHeader'
import { IResourcePeople, ResourcesFilterTypes } from 'containers/ListIndexView/ResourcesList/types'
import { useScrollToElement } from 'containers/ListIndexView/hooks'
import { StickyHeader } from 'containers/ListIndexView/style'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'

import ResourcePeopleAddWidget from './ResourcePeopleAddWidget'
import ResourcePeopleAdminView from './ResourcePeopleAdminView'
import ResourcePeopleMemberView from './ResourcePeopleMemberView'

import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  listLoading: boolean
  memberView: boolean
  // adminView: boolean
  resourcesListEmpty: boolean
  sharedListView: boolean
  filters: ResourcesFilterTypes
  setFilters: (filters: ResourcesFilterTypes) => void
  listUuid: string
  tags: string[]
  fetchListData: () => void
  filterResultEmpty: boolean
}

const ResourcePeopleList: React.FC<Props> = ({
  filters,
  setFilters,
  listLoading,
  resourcesListEmpty,
  memberView,
  sharedListView,
  listUuid,
  tags,
  fetchListData,
  filterResultEmpty,
}) => {
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState<IResourcePeople[]>([])
  const { adminView, resourcePeople, companyListData, view } = useResourcesListContext()
  const scrollToAdd = useScrollToElement('resource-people-add')

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const count = companyListData.item_count > 16 ? 16 : companyListData.item_count

  return (
    <div ref={parent} id="resource-people-list">
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <ResourcesListHeader />
          <div className="flex gap-6">
            <ResourcesListBulkActions selectedItems={selectedPeople} listUuid={listUuid} />
            <ResourcesListCTAs
              onShowAddWidget={() => {
                setShowAddWidget(!showAddWidget)
                if (!showAddWidget) {
                  scrollToAdd()
                }
              }}
            />
          </div>
        </div>
        <ResourcesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={tags}
          locations={resourcePeople
            .map((p) => {
              if (p.location) {
                return p.location
              }
            })
            .filter((value, index, self) => {
              return self.indexOf(value) === index
            })}
          setSelectedItems={setSelectedPeople}
        />
      </StickyHeader>
      {listLoading && <SkeletonDataCard view={view} itemCount={count} />}
      {!listLoading && (
        <>
          <div ref={parent} id="resource-people-add">
            {showAddWidget && (
              <ResourcePeopleAddWidget
                onClose={() => setShowAddWidget(false)}
                listUuid={listUuid}
                fetchListData={fetchListData}
              />
            )}
          </div>

          {filterResultEmpty && <NoResultsPage />}

          {resourcesListEmpty && !showAddWidget && (
            <ResourcesListEmptyState
              onShowAddWidget={() => {
                setShowAddWidget(!showAddWidget)
              }}
              title="No people"
              description="Add people to get started"
              buttonLabel="Add people"
            />
          )}

          {adminView && !resourcesListEmpty && (
            <ResourcePeopleAdminView
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
            />
          )}

          {(memberView || sharedListView) && !resourcesListEmpty && <ResourcePeopleMemberView />}
        </>
      )}
    </div>
  )
}

export default ResourcePeopleList
