import React from 'react'

import LoginPage from 'containers/LoginPage'
import Widget from 'ui-components/Widget'

const IntroRequestPublicSuccess = () => {
  return (
    <Widget classNames="w-96 p-8">
      <LoginPage isModal={true} />
    </Widget>
  )
}

export default IntroRequestPublicSuccess
