import axios from 'axios'

type AffinityStatus = {
  success: boolean
  status: {
    queued_at: string | null
    synced_at: string | null
  }
}

export default {
  getAffinityLists: () => {
    return axios.get<{ lists: { id: string; name: string }[] }>(`/api/affinity_lists`)
  },

  syncAffinityLists: () => {
    return axios.post<AffinityStatus>(`/api/affinity_lists/sync`)
  },

  getAffinitySyncStatus: () => {
    return axios.get<AffinityStatus>('/api/external_sources/affinity_sync_status')
  },

  getSelectedAffinityLists: () => {
    return axios.get<{ lists: [] }>('/api/affinity_lists/selected_lists', {})
  },

  updateAffinityListFilters: (params: any) => {
    return axios.post('/api/affinity_lists/save_selected_lists', {
      ...params,
    })
  },
}
