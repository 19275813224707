import React from 'react'

import AddWidget from 'containers/ListIndexView/Components/AddWidget'

import AddIndividualSection from './AddIndividualSection'

interface Props {
  onClose: () => void
}

const ResourceCompanyAddWidget = ({ onClose }: Props) => {
  const tabs = [
    {
      key: 'add_individually',
      label: 'Add Individually',
      iconClass: 'far fa-plus',
      component: <AddIndividualSection setOnClose={onClose} />,
    },
    // {
    //   key: 'import',
    //   label: 'Import',
    //   iconClass: 'far fa-file-csv',
    //   component: <ImportSection setOnClose={onClose} />,
    // },
  ]

  return <AddWidget onClose={onClose} tabs={tabs} title="Add person" />
}

export default ResourceCompanyAddWidget
