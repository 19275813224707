import React, { useEffect, useState } from 'react'

import { useMutation } from 'react-query'

import { useComposer } from 'components/Composer'
import { StyledFontAwesomeIcon } from 'components/Winsite/styles'
import { ICreateMessageDraftPayload } from 'containers/IntroRequestModal/components/types'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'

import api, { callApi } from 'utils/api'

interface IDraftEmailObject {
  draftFromName: string | null
  draftToName: string | null
  messageDraftUuid: string | null | undefined
  teamSlug: string
  messageDraftCreate: ICreateMessageDraftPayload
  setMessageDraftUuid: (message_uuid: string) => void
  introRequestUuid: string | null | undefined
  setShowModal: (showModal: boolean) => void
}
interface IProps {
  draftEmailObject: IDraftEmailObject
}

export function DraftEmailAttachment({ draftEmailObject }: IProps) {
  const {
    draftFromName,
    draftToName,
    messageDraftUuid,
    messageDraftCreate,
    setMessageDraftUuid,
    teamSlug,
    setShowModal,
  } = draftEmailObject

  const [attachmentVisible, setAttachmentVisible] = useState<boolean>(false)
  const [draftUuid, setDraftUuid] = useState<string | null | undefined>(messageDraftUuid)
  const { compose } = useComposer()

  const { mutate: createMessageMutate } = useMutation({
    mutationFn: (createMessageDraftPayload: ICreateMessageDraftPayload) =>
      callApi(api.createMessageDraft, createMessageDraftPayload),
  })

  const createMessage = () => {
    if (!draftUuid) {
      createMessageMutate(messageDraftCreate, {
        onSuccess: (response) => {
          const { message_uuid } = response
          setMessageDraftUuid(message_uuid)
          setDraftUuid(message_uuid)
          handleOpenComposer(message_uuid) // Pass the new draft UUID to the function
        },
        onError: () => {
          console.log('Error')
        },
      })
    }
  }

  useEffect(() => {
    createMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!draftUuid) {
    return null
  }

  const onHide = () => setShowModal(true)

  const onAfterDelete = () => setAttachmentVisible(true)

  const handleOpenComposer = (uuid?: string) => {
    setShowModal(false)
    const messageUuid = uuid || draftUuid

    if (messageUuid) {
      compose({
        messageUuid: messageUuid,
        team_slug: teamSlug,
        introRequestFlow: true,
        onHide: onHide,
        onAfterDelete: onAfterDelete,
      })
    }
  }

  const handleCreateAndOpenComposer = () => {
    setDraftUuid(null)

    createMessageMutate(messageDraftCreate, {
      onSuccess: (response) => {
        const { message_uuid } = response
        setMessageDraftUuid(message_uuid)
        setDraftUuid(message_uuid)
        handleOpenComposer(message_uuid)
      },
      onError: () => {
        console.log('Error')
      },
    })
    setAttachmentVisible(false)
  }

  const draftAttachment = (
    <div onClick={() => handleCreateAndOpenComposer()} className="mt-0">
      <div className="flex items-center">
        <CabalButton size="medium" variant="link" className="pl-0">
          <Typography className="flex gap-2 items-center" fontSize="12" lineHeight={1}>
            <i className="fa-solid fa-paperclip" />
            <div style={{ maxWidth: '444px' }} className="w-full block truncate">
              Attach draft from {draftFromName} to {draftToName}
            </div>
          </Typography>
        </CabalButton>
      </div>
    </div>
  )

  const draftPill = (
    <div className="flex justify-between items-center">
      <PillV2
        onClick={() => {}}
        title={
          <Typography
            className="flex gap-2 justify-between items-center"
            fontSize="12"
            lineHeight={1}
          >
            <i className="fa-solid fa-paperclip" onClick={() => handleOpenComposer()} />
            <span className="block" onClick={() => handleOpenComposer()}>
              <div style={{ maxWidth: '400px' }} className="w-full block truncate">
                Draft email from {draftFromName} to {draftToName}
              </div>
            </span>
            <StyledFontAwesomeIcon
              className={'far fa-solid fa-x fa-xs'}
              onClick={() => setAttachmentVisible(true)}
            ></StyledFontAwesomeIcon>
          </Typography>
        }
        className="bg-gray-100"
      ></PillV2>
    </div>
  )

  return <div style={{ minHeight: '34px' }}>{attachmentVisible ? draftAttachment : draftPill}</div>
}
