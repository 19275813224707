import React, { useState } from 'react'

import view from '@ckeditor/ckeditor5-ui/src/view'
import { count } from '@ckeditor/ckeditor5-utils'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import NoResultsPage from 'containers/ListIndexView/Components/NoResultsPage'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceDiscountAddModal from 'containers/ListIndexView/ResourcesList/ResourceDiscountList/ResourceDiscountAddModal'
import ResourceDiscountView from 'containers/ListIndexView/ResourcesList/ResourceDiscountList/ResourceDiscountView'
import AddResourcePersonModal from 'containers/ListIndexView/ResourcesList/ResourcePeopleList/ResourcePeopleAddWidget/AddResourcePersonModal'
import ResourcePeopleAdminView from 'containers/ListIndexView/ResourcesList/ResourcePeopleList/ResourcePeopleAdminView'
import ResourcePeopleMemberView from 'containers/ListIndexView/ResourcesList/ResourcePeopleList/ResourcePeopleMemberView'
import ResourcesListBulkActions from 'containers/ListIndexView/ResourcesList/ResourcesListBulkActions'
import ResourcesListCTAs from 'containers/ListIndexView/ResourcesList/ResourcesListCTAs'
import ResourcesListEmptyState from 'containers/ListIndexView/ResourcesList/ResourcesListEmptyState'
import ResourcesListFilters from 'containers/ListIndexView/ResourcesList/ResourcesListFilters'
import ResourcesListHeader from 'containers/ListIndexView/ResourcesList/ResourcesListHeader'
import { IDiscount, ResourcesFilterTypes } from 'containers/ListIndexView/ResourcesList/types'
import { StickyHeader } from 'containers/ListIndexView/style'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import { useTeamSlug } from 'store/hooks'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'

interface Props {
  filters: ResourcesFilterTypes
  setFilters: (filters: ResourcesFilterTypes) => void
  listLoading: boolean
  memberView: boolean
  resourcesListEmpty: boolean
  sharedListView: boolean
  filterResultEmpty: boolean
}

const ResourceDiscountList: React.FC<Props> = ({
  filters,
  setFilters,
  listLoading,
  memberView,
  resourcesListEmpty,
  sharedListView,
  filterResultEmpty,
}) => {
  const [selectedDiscounts, setSelectedDiscounts] = useState<IDiscount[]>([])
  const { createOrModifyListItem, tags, companyListData, refetchCompanyList, view } =
    useResourcesListContext()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const count = companyListData.item_count > 16 ? 16 : companyListData.item_count

  return (
    <div>
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <ResourcesListHeader />
          <div className="flex gap-6">
            <ResourcesListBulkActions
              selectedItems={selectedDiscounts}
              listUuid={companyListData.uuid}
            />
            <ResourcesListCTAs
              onShowAddWidget={() =>
                showModal(
                  (resolve) => (
                    <ResourceDiscountAddModal
                      onHide={resolve}
                      teamSlug={teamSlug}
                      onSave={createOrModifyListItem}
                      tags={tags}
                      listUuid={companyListData.uuid}
                      fetchListData={refetchCompanyList}
                    />
                  ),
                  'add-discount-modal',
                )
              }
            />
          </div>
        </div>
        <ResourcesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={tags}
          locations={[]}
          setSelectedItems={setSelectedDiscounts}
        />
      </StickyHeader>

      {listLoading && <SkeletonDataCard view={view} itemCount={count} />}

      {!listLoading && (
        <>
          {resourcesListEmpty && (
            <ResourcesListEmptyState
              onShowAddWidget={() => {
                showModal(
                  (resolve) => (
                    <ResourceDiscountAddModal
                      onHide={resolve}
                      teamSlug={teamSlug}
                      onSave={createOrModifyListItem}
                      tags={tags}
                      listUuid={companyListData.uuid}
                      fetchListData={refetchCompanyList}
                    />
                  ),
                  'add-discount-modal',
                )
              }}
              title="No discounts"
              description="Add discounts to get started"
              buttonLabel="Add discounts"
            />
          )}

          {filterResultEmpty && <NoResultsPage />}

          {!resourcesListEmpty && (
            <ResourceDiscountView
              selectedItems={selectedDiscounts}
              setSelectedItems={setSelectedDiscounts}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ResourceDiscountList
