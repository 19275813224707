import axios from 'axios'
export default {
  exportSalesList: (itemUuids: string[], listUuid: string) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/export`, { item_uuids: itemUuids})
  },
  addElementToSalesList: (
    listUuid: string,
    data: { item_uuid: string } | {linkedin_url: string },
  ) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/add`, {
      ...data,
    })
  },
  removeElementFromSalesList: (listUuid: string, itemUuid: string[]) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/remove`, { uuids: itemUuid })
  },
  importSales: (uploadUrl: string, uploadUuid: string, listUuid: string) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/import`, {
      upload_url: uploadUrl,
      upload_uuid: uploadUuid,
    })
  },
  importSalesStatus: (importJobId: number, listUuid: string) => {
    return axios.get(`/api/v2/sales_list/${listUuid}/import_status?import_job_id=${importJobId}`)
  },
}
