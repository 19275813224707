import * as React from 'react'

import axios, { AxiosError } from 'axios'
import cx from 'classnames'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useMount, useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import defaultLists from 'containers/SignupScreen/ResourcesPage/defaultLists'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import useColorMode from 'utils/hooks/useColorMode'

import GoogleLogo from '../../images/google-icon.png'
import MicrosoftLogo from '../../images/microsoft-icon.png'
import {
  LoginFormContainer,
  OptionText,
  SignupContainer,
  SuccessPrimary,
} from '../SignupScreen/styles'

const Container = styled.div`
  max-width: 375px;
  margin: 0 auto;
  margin-top: 80px;

  @media (min-width: 650px) {
    margin-top: 180px;
  }
`

const LogoContainer = styled.div`
  ${tw`text-center`}
`

const Logo = styled.img`
  ${tw`inline-block`}
  height: 64px;
  width: 64px;
  margin-bottom: 78px;
`

const NavWrapper = styled.p`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #718096 !important;
`

const NavText = styled.p`
  font-size: 12px;
  color: #718096 !important;
`

export const Login = () => {
  const [email, setEmail] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const { isLoggedIn } = useCurrentUser()

  const provider = useSearchParam('provider')
  const error = useSearchParam('auth_failed')

  const { mutate: sendLoginLink, isLoading: sendingLoginLink } = useMutation(
    ['sendLoginLink'],
    () => axios.post('/auth/request_login_link.json', { email }),
    {
      onSuccess: ({ data }) => {
        setSuccess(true)
        if (data.redirect) {
          window.location.assign('/')
        }
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === 404) {
          cabalToast({ style: 'error', content: e.response.data.error })
          setSuccess(false)
        }
      },
    },
  )

  useMount(() => {
    if (provider === 'microsoft') {
      if (error) {
        cabalToast({ style: 'error', content: error })
      }
    }
  })

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    sendLoginLink()
  }

  return (
    <LoginFormContainer>
      {success ? (
        <div className="flex justify-center flex-col items-center">
          <SuccessPrimary className="mt-4 whitespace-pre">
            <i className="far fa-check-circle pr-3"></i>
            {`Email sent to ${email}!`}
          </SuccessPrimary>
          <p className="pt-2">{`Please check your email for a login link`}</p>
          <CabalButton
            className={'pt-2 cursor-pointer'}
            variant="link"
            size={'large'}
            onClick={() => setSuccess(false)}
            component={'a'}
          >{`Wrong email address? Try again`}</CabalButton>
        </div>
      ) : (
        <div>
          <a style={{ border: 0, outline: 'none' }} href="/users/auth/google_oauth2">
            <CabalButton
              variant="auth"
              leftIcon={<img src={GoogleLogo} width="18" />}
              className="mt-8"
            >
              <Typography fontWeight={600} fontSize={'14'}>
                Sign in with Google
              </Typography>
            </CabalButton>
          </a>

          <a
            style={{ border: 0, outline: 'none' }}
            href={`/auth/microsoft?failure_return_to=${window.location.pathname}`}
          >
            <CabalButton
              variant="auth"
              leftIcon={<img src={MicrosoftLogo} width="18" />}
              className="mt-8"
            >
              <Typography fontWeight={600} fontSize={'14'}>
                Sign in with Microsoft
              </Typography>
            </CabalButton>
          </a>

          <OptionText>{`or`}</OptionText>
          <form className="mx-auto" onSubmit={onSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className={cx('mt-4')}
              required
            />
            <button disabled={email === '' || sendingLoginLink}>
              {/* <i className="far fa-key pr-3"></i> */}
              Send me a login link
            </button>
          </form>
        </div>
      )}
    </LoginFormContainer>
  )
}

const LoginPage = ({ isModal = false }: { isModal?: boolean }) => {
  const location = useLocation<{ from: { pathname: string } }>()
  const year = new Date().getFullYear()
  const { isDarkMode } = useColorMode()
  const { team } = useTeam(location.state?.from.pathname.split('/')[1])

  React.useEffect(() => {
    if (location.state?.from?.pathname.includes('/get-resource/')) {
      const [categoryName, index] = location.state.from.pathname
        .split('/get-resource/')[1]
        .split('/')
      const list =
        defaultLists.lists[categoryName as keyof typeof defaultLists.lists]?.lists[
          parseInt(index, 10)
        ]
      cabalToast({ content: `Sign in to access the "${list?.name}" resource`, style: 'info' })
    } else if (location.state?.from) {
      cabalToast({ content: 'Please log in to continue', style: 'info' })
    } else if (new URLSearchParams(location.search).get('error') === 'true') {
      cabalToast({ content: 'There was an error logging in. Please try again.', style: 'error' })
    }
  }, [])

  const loginSection = (
    <div>
      {team ? (
        <LogoContainer className="mb-16">
          <Avatar src={team.logo} name={team.name} size="64" round />
          <p className="mt-6">{`Login to view ${team.name}`}</p>
        </LogoContainer>
      ) : (
        <LogoContainer>
          {isDarkMode ? (
            <Logo src={'/cabal-svg-dark.svg'} />
          ) : (
            <Logo src={'/cabal-svg-light.svg'} />
          )}
        </LogoContainer>
      )}
      <Login />
    </div>
  )

  if (isModal) {
    return loginSection
  }

  return (
    <SignupContainer>
      <CabalTitle title="Login" />
      <Container className="flex justify-between flex-col h-5/6 sm:h-3/4 px-2">
        {loginSection}

        <NavWrapper className="pb-4">
          <a href="/privacy" className="mr-1">
            <NavText>{`Privacy`}</NavText>
          </a>
          <span>{` · `}</span>
          <a href="/terms" className="mx-1">
            <NavText>{`Terms`}</NavText>
          </a>
          <span className="mr-1">{` · `}</span>
          {`Cabal © ${year}`}
        </NavWrapper>
      </Container>
    </SignupContainer>
  )
}

export default LoginPage
