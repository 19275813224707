import React from 'react'

import { useMutation } from 'react-query'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const ResourcesListBulkActions = ({ selectedItems, listUuid }) => {
  const { adminView, refetchResourceList, view, setView } = useResourcesListContext()
  const teamSlug = useTeamSlug()
  const { mutate: deleteResourceItem } = useMutation(
    () =>
      api.removeListItems(
        teamSlug,
        listUuid,
        selectedItems.map((item) => item.uuid),
      ),
    {
      onSuccess: () => {
        refetchResourceList()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const { mutate: exportPeople } = useMutation(
    () =>
      callApi(
        api.exportResourcePeopleList,
        selectedItems.map((item) => item.uuid),
        listUuid,
      ),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Company data export initiated. Check your email for the file.',
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to export company data.' })
      },
    },
  )
  const handleOnSelect = (action: 'delete' | 'export') => {
    if (selectedItems.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        deleteResourceItem()
      }
    }

    if (action === 'export') {
      exportPeople()
    }
  }

  const bulkMenuItems = [
    {
      label: 'Delete',
      onSelect: () => handleOnSelect('delete'),
    },
    {
      label: 'Export',
      onSelect: () => handleOnSelect('export'),
    },
  ]

  return (
    <div>
      {adminView && (
        <BulkActions
          onClick={() => view === 'grid' && setView('list')}
          disabled={selectedItems.length === 0}
          bulkMenuItems={bulkMenuItems}
        />
      )}
    </div>
  )
}

export default ResourcesListBulkActions
