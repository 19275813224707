import React from 'react'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  onShowAddWidget: () => void
}

const SalesListEmptyState: React.FC<Props> = ({ onShowAddWidget }) => {
  const { adminView } = useSalesListContext()

  return (
    <div className="flex items-center justify-center my-16">
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography color="fog" fontSize="20">
          <i className="far fa-building" />
        </Typography>
        <Typography fontSize="14" lineHeight={1}>
          No people
        </Typography>

        {adminView && (
          <>
            <Typography fontSize="12" lineHeight={1}>
              Add people to get started
            </Typography>
            <CabalButton onClick={() => onShowAddWidget()} leftIcon={<i className="far fa-plus" />}>
              Add people
            </CabalButton>
          </>
        )}
      </div>
    </div>
  )
}

export default SalesListEmptyState
